import { checkLoggedIn } from './functions'

/**
 * @description Check if user is logged in
 */
const isLoggedIn: boolean = checkLoggedIn()

/**
 * @description Return URL
 */
const returnUrl = ''

export default {
  isLoggedIn,
  returnUrl
}
