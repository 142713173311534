import { defineStore } from 'pinia'
import api from '@/services/api'
import { JWTToken, KeyAndPasswordVM, LoginVM } from '@/models'
import { AxiosResponse } from 'axios'
import router from '@/router'
import config from '@/helpers/config'
import { useAccountStore } from './account.store'

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    token: {
      idToken: JSON.parse(localStorage.getItem('token') as string)
    } as JWTToken,
    returnUrl: config.returnUrl,
    accountStore: useAccountStore()
  }),
  actions: {
    authenticate: async function (payload: LoginVM): Promise<AxiosResponse<any>> {
      return await api.post('/api/authenticate', JSON.stringify(payload))
    },

    getUserData: async function () {
      return await api.get('/api/account')
    },

    forgotPasswordInit: async function (email: string) {
      return await api.patch(`/api/account/reset-password/init/${email}`)
    },

    forgotPasswordFinit: async function (payload: KeyAndPasswordVM) {
      return await api.post('/api/account/reset-password/finish', JSON.stringify(payload))
    },

    updatePassword: async function (currentPassword: string, newPassword: string) {
      return await api.post('/api/account/change-password', {
        currentPassword,
        newPassword
      })
    },

    setToken: function (token: JWTToken) {
      this.token = token
      localStorage.setItem('token', JSON.stringify(this.token.idToken))
    },

    setReturnUrl: function (returnUrl: string) {
      this.returnUrl = returnUrl
    },

    logout: function (expired = '') {
      this.token = {} as JWTToken
      config.isLoggedIn = false
      router.push({
        name: 'Login',
        params: { returnUrl: this.returnUrl, username: this.accountStore.accountData.login, expired }
      })
      localStorage.clear()
    }
  }
})
