
import config from '@/helpers/config'
import { useMenuStore } from '@/store/menu.store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NavBar',
  computed: {
    selectedLanguage() {
      if (this.$i18n.locale === 'en') {
        return 'English'
      }
      return 'Français'
    }
  },
  setup() {
    const menuStore = useMenuStore()
    return {
      config,
      menuStore
    }
  },
  data: () => ({
    drawer: false
  }),
  methods: {
    changeLanguage(code: string) {
      this.$i18n.locale = code
    }
  }
})
