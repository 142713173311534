
import { getAppBaseUrl } from '@/helpers/functions'
import QRCodeStyling, {
  CornerDotType,
  CornerSquareType,
  DotType,
  DrawType,
  ErrorCorrectionLevel,
  Mode,
  TypeNumber
} from 'styled-qr-code'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'QRCodeGenerator',
  props: {
    width: {
      type: Number,
      default: 150
    },
    margin: {
      type: Number,
      default: 2
    }
  },
  mounted() {
    this.qrCode.append(this.$refs.qrCodeCanvas as HTMLDivElement)
  },
  setup: props => {
    const data = ref(`${getAppBaseUrl()}/app/download`)

    const options = {
      width: props.width,
      height: props.width,
      type: 'svg' as DrawType,
      data: data.value,
      margin: props.margin,
      dotsOptions: {
        color: '#000000',
        type: 'extra-rounded' as DotType
      },
      qrOptions: {
        typeNumber: 0 as TypeNumber,
        mode: 'Byte' as Mode,
        errorCorrectionLevel: 'Q' as ErrorCorrectionLevel
      },
      backgroundOptions: {
        color: '#e9ebee'
      },
      cornersSquareOptions: {
        color: '#000000',
        type: 'extra-rounded' as CornerSquareType
      },
      cornersDotOptions: {
        color: '#000000',
        type: 'dot' as CornerDotType
      }
    }

    const qrCode = new QRCodeStyling(options)

    return {
      qrCode
    }
  }
})
