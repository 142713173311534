/* tslint:disable */
/* eslint-disable */
/**
 * Transfert API
 * Transfert API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Bill } from './bill'
import { Client } from './client'
import { Receiver } from './receiver'
/**
 *
 * @export
 * @interface Transaction
 */
export interface Transaction {
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  amount?: number
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  amountXfa?: number
  /**
   *
   * @type {Bill}
   * @memberof Transaction
   */
  bill?: Bill
  /**
   *
   * @type {Client}
   * @memberof Transaction
   */
  client?: Client
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  paymentMode?: string
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  phoneNumber?: string
  /**
   *
   * @type {Receiver}
   * @memberof Transaction
   */
  receiver?: Receiver
  /**
   *
   * @type {Date}
   * @memberof Transaction
   */
  transactionDate?: Date
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  transactionFee?: number
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  transactionId?: string
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  transactionInfo?: string
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  transactionRate?: number
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  transactionStatus?: TransactionTransactionStatusEnum
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  transactionType?: TransactionTransactionTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum TransactionTransactionStatusEnum {
  DONE = 'DONE',
  INIT = 'INIT',
  PEDDINT = 'PEDDINT',
  FAILURE = 'FAILURE'
}
/**
 * @export
 * @enum {string}
 */
export enum TransactionTransactionTypeEnum {
  BANKTRANSFERE = 'BANKTRANSFERE',
  BILLPAY = 'BILLPAY',
  MOBILETRANSFERE = 'MOBILETRANSFERE'
}

/**
 * @exports
 * @class Transaction
 */
export class Transaction implements Transaction {
  constructor(data?: Transaction) {
    Object.assign(this, data)
  }

  /**
   *
   * @param json
   * @returns
   */
  public static deserialize(json: any): Transaction {
    return new Transaction({
      amount: json.amount,
      amountXfa: json.amountXfa,
      bill: json.bill,
      client: json.client,
      id: json.id,
      paymentMode: json.paymentMode,
      phoneNumber: json.phoneNumber,
      receiver: json.receiver,
      transactionDate: json.transactionDate,
      transactionFee: json.transactionFee,
      transactionId: json.transactionId,
      transactionInfo: json.transactionInfo,
      transactionRate: json.transactionRate,
      transactionStatus: json.transactionStatus,
      transactionType: json.transactionType
    })
  }
}
