
import { defineComponent } from 'vue'
import { jsPDF as JsPDF } from 'jspdf'
import { formatDate, formatDateTime, sortListOfTransactionDTOByDateDESC } from '@/helpers/functions'
import { TransactionDTO, TransactionDTOTransactionStatusEnum, TransactionDTOTransactionTypeEnum } from '@/models'
import { useTransactionsHistoryStore } from '@/store/transactions/history.store'
import { useAccountStore } from '@/store/auth/account.store'

export default defineComponent({
  name: 'PdfReport',
  computed: {
    transactionsStore: function () {
      return useTransactionsHistoryStore()
    },

    accountStore: function () {
      return useAccountStore()
    }
  },
  methods: {
    generatePdf: function () {
      const doc = new JsPDF({
        format: 'a4',
        compress: true
      })

      // Add 'Account Number' text: align it to the left
      doc.setFontSize(11)
      doc.setFont('Roboto', 'normal')
      doc.text('Account Number:', 10, 50, { align: 'left' })
      doc.text(`${this.accountStore.accountData.id}`, 45, 50, { align: 'left' })

      // add 'Statement Date' text: align it to the left
      doc.text('Statement Date:', 10, 55, { align: 'left' })
      doc.text(formatDate(new Date()), 45, 55, { align: 'left' })

      // Add 'User Name' text: align it to the right and bold
      doc.setFontSize(12)
      doc.setFont('Roboto', 'bold')
      doc.text(`${this.accountStore.accountData.firstName} ${this.accountStore.accountData.lastName}`, 200, 43, {
        align: 'right'
      })

      // Add 'User Email' text: align it to the right
      doc.setFontSize(11)
      doc.setFont('Roboto', 'normal')
      doc.text(`${this.accountStore.accountData.email}`, 200, 50, { align: 'right' })

      // Add 'User phone number' text: align it to the right
      doc.text(`${this.accountStore.accountData.phoneNumber}`, 200, 55, { align: 'right' })

      // Add Table header: background color
      doc.setFontSize(9)
      doc.setFont('Roboto', 'bold')
      doc.setFillColor('#8cc442')
      doc.text('Date', 10, 70, { align: 'left' })
      doc.text('Beneficiary', 30, 70, { align: 'left' })
      doc.text('Operator', 70, 70, { align: 'left' })
      doc.text('Status', 110, 70, { align: 'left' })
      doc.text('Amount(CAD)', 150, 70, { align: 'right' })
      doc.text('Amount(XAF)', 180, 70, { align: 'right' })
      doc.text('Rate', 200, 70, { align: 'right' })

      // And border on table header
      doc.setDrawColor(0, 0, 0)
      doc.line(10, 72, 200, 72)

      // Add Table content and create new page if needed
      let y = 80
      sortListOfTransactionDTOByDateDESC(this.transactionsStore.transactions as TransactionDTO[]).forEach(
        transaction => {
          doc.setFontSize(10)
          doc.setFont('Roboto', 'normal')
          doc.text(formatDate(transaction.transactionDate as any), 10, y, { align: 'left' })
          doc.text(this.getfullName(transaction), 30, y, { align: 'left' })
          doc.text(this.paymentMode(transaction), 70, y, { align: 'left' })
          doc.text(this.transationStatus(transaction), 110, y, { align: 'left' })
          doc.text(transaction.amount!.toString(), 150, y, { align: 'right' })
          doc.text(transaction.amountXfa!.toString(), 180, y, { align: 'right' })
          doc.text(transaction.transactionRate!.toString(), 200, y, { align: 'right' })
          y += 8
          if (y > 267) {
            doc.addPage()
            y = 60

            // Add Table header: background color
            doc.setFontSize(9)
            doc.setFont('Roboto', 'bold')
            doc.setFillColor('#8cc442')
            doc.text('Date', 10, 50, { align: 'left' })
            doc.text('Beneficiary', 30, 50, { align: 'left' })
            doc.text('Operator', 70, 50, { align: 'left' })
            doc.text('Status', 110, 50, { align: 'left' })
            doc.text('Amount(CAD)', 150, 50, { align: 'right' })
            doc.text('Amount(XAF)', 180, 50, { align: 'right' })
            doc.text('Rate', 200, 50, { align: 'right' })

            // And border on table header
            doc.setDrawColor(0, 0, 0)
            doc.line(10, 52, 200, 52)
          }
        }
      )

      // Add 'current page / total pages' in the footer, align it to the right
      const pageCount = doc.getNumberOfPages()
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i)

        // Add logo image
        const img = new Image()
        img.src = require('@/assets/logo.png')
        doc.addImage(img, 'PNG', 10, 10, 30, 25, '', 'FAST')

        // App address
        doc.setFontSize(11)
        doc.setFont('Roboto', 'normal')
        doc.text('620 RUE LABBÉ LEVIS, QC, CANADA G6K 1W4', 45, 20)
        doc.text('Tel: +1 (581) 681-3427', 45, 25)
        doc.text('Site: https://www.gtfinance.ca', 45, 30)

        // Add 'ACCOUNT STATEMENT' text: align it to the right and bold
        doc.setFontSize(15)
        doc.setFont('Roboto', 'bold')
        doc.text('ACCOUNT STATEMENT', 200, 25, { align: 'right' })

        doc.setFontSize(10)
        doc.setFont('Roboto', 'normal')
        doc.text(`Page ${i}/${pageCount}`, 200, 285, { align: 'right' })
      }

      // Save the pdf
      doc.save(`Transactions ${formatDateTime(new Date())}.pdf`)
    },
    paymentMode: function (transaction: TransactionDTO) {
      if (transaction.paymentMode === 'MTNMOMO') {
        return 'MTN Mobile Money'
      } else if (transaction.paymentMode === 'CMORANGEMOMO') {
        return 'Orange Money'
      } else {
        return this.getfullName(transaction)
      }
    },
    getfullName: function (transaction: TransactionDTO) {
      if (transaction.transactionType === TransactionDTOTransactionTypeEnum.BILLPAY) {
        const provider = transaction.paymentMode?.split('_') || []
        return `${provider[1]}`
      }
      return `${transaction.receiver!.firstName} ${transaction.receiver!.lastName}`
    },
    transationStatus: function (transaction: TransactionDTO) {
      if (transaction.transactionStatus === TransactionDTOTransactionStatusEnum.PEDDINT) {
        return 'Pending'
      } else if (transaction.transactionStatus === TransactionDTOTransactionStatusEnum.DONE) {
        return 'Success'
      } else if (transaction.transactionStatus === TransactionDTOTransactionStatusEnum.INIT) {
        return 'Initiated'
      } else {
        return 'Failed'
      }
    }
  }
})
