import { ManagedUserVM } from '@/models'
import api from '@/services/api'
import { defineStore } from 'pinia'

export const useRegisterStore = defineStore({
  id: 'register',
  state: () => ({
    user: null as ManagedUserVM | null,
    phone: null as unknown as number
  }),
  actions: {
    createAccount: async function (payload: ManagedUserVM) {
      return await api.post<ManagedUserVM>('/api/register', JSON.stringify(payload))
    },
    setPhone: function (payload: number) {
      this.phone = payload
    },
    limitText: function (payload: string) {
      if (payload.length > 10) {
        this.setPhone(Number(payload.slice(0, 10)))
      }
    },
    reset: function () {
      this.user = null as ManagedUserVM | null
      this.phone = null as unknown as number
    }
  }
})

export const useActivateAccountStore = defineStore({
  id: 'activateAccount',
  state: () => ({
    user: null as ManagedUserVM | null
  }),
  actions: {
    activateAccount: async function (payload: { email: string; activationKey: string }) {
      return await api.get<{ email: string; activationKey: string }>('/api/activate', {
        params: { key: payload.activationKey, email: payload.email }
      })
    }
  }
})
