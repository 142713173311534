import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import './assets/style/style.css'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import { createPinia, PiniaVuePlugin } from 'pinia'
import Toast, { POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import { PluginOptions } from 'vue-toastification/dist/types/src/types'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
Vue.component('Loading', Loading)

Vue.config.productionTip = false

const toastOptions: PluginOptions = {
  position: POSITION.BOTTOM_RIGHT,
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  closeButton: 'button',
  hideProgressBar: true,
  icon: true,
  rtl: false
}

Vue.use(Toast, toastOptions)
Vue.use(VueSweetalert2)
new Vue({
  router,
  vuetify,
  i18n,
  pinia,
  render: h => h(App)
}).$mount('#app')
