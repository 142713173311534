
import { formatDate } from '@/helpers/functions'
import { EditAccountRequestDto } from '@/models'
import { useAccountStore } from '@/store/auth/account.store'
import moment from 'moment'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InfoPersoSection',
  computed: {
    formatBirthDate: function (): string {
      moment.locale(this.$i18n.locale)
      return this.birthDate ? moment(this.birthDate).format('LL') : ''
    },
    maxDate: function (): string {
      return new Date(this.subtractYears(new Date(Date.now()), 18)).toISOString().substring(0, 10)
    },
    formatDate: function () {
      return formatDate
    },
    accountStore: function () {
      return useAccountStore()
    }
  },
  beforeMount: function () {
    this.firstName = this.accountStore.accountData.firstName as string
    this.lastName = this.accountStore.accountData.lastName as string
    this.birthDate = this.accountStore.accountData.birthDate
      ? this.accountStore.accountData.birthDate.toString().substring(0, 10)
      : ''
  },
  watch: {
    firstName: function (val: string) {
      this.firstName = val
        .split(' ')
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ')
    },
    lastName: function (val: string) {
      this.lastName = val.toUpperCase()
    }
  },
  data: function () {
    return {
      firstNameRules: [(value: string) => !!value || this.$t('firstNameError')],
      lastNameRules: [(value: string) => !!value || this.$t('lastNameError')],
      birthDateRules: [(value: string) => !!value || this.$t('birthDateError')],
      isEdit: false,
      menu: false,
      personalInfoValid: false,
      birthDate: '',
      showLoader: false,
      firstName: '',
      lastName: ''
    }
  },
  methods: {
    subtractYears: function (date: Date, years: number): number {
      return date.setFullYear(date.getFullYear() - years)
    },
    updateUser: function () {
      const form = this.$refs.personalInfo as any
      form.validate()
      if (this.personalInfoValid) {
        this.showLoader = true

        const payload: EditAccountRequestDto = {
          firstName: this.firstName,
          lastName: this.lastName,
          birthDate: new Date(this.birthDate)
        }

        this.accountStore
          .updateUser(payload)
          .then(async () => {
            this.$toast.success(this.$t('userUpdated'))
            await this.accountStore.refreshData()
          })
          .catch(error => {
            if (error.response.status === 400) {
              this.$toast.error(this.$t('error400Msg'))
            }
            if (error.response.status === 401) {
              this.$toast.error(this.$t('error401Msg'))
            }
            if (error.response.status === 500) {
              this.$toast.error(this.$t('error500Msg'))
            }
          })
          .finally(() => {
            this.showLoader = false
          })
      }
    }
  }
})
