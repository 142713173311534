import { ReceiverDTO } from '@/models'
import api from '@/services/api'
import { defineStore } from 'pinia'

export const useReceiverStore = defineStore({
  id: 'receiver',
  state: () => ({
    receiver: null as ReceiverDTO | null,
    receivers: [] as ReceiverDTO[],
    loading: false,
    pagination: {
      page: 1,
      rowsPerPage: 5
    }
  }),
  getters: {
    displayedItems: function (state) {
      return state.receivers.slice(
        (state.pagination.page - 1) * state.pagination.rowsPerPage,
        state.pagination.page * state.pagination.rowsPerPage
      )
    }
  },
  actions: {
    getReceivers: async function () {
      this.receivers = []
      this.loading = true
      await api
        .get('/api/receivers')
        .then(response => {
          response.data.forEach((receiver: any) => {
            this.receivers.push(ReceiverDTO.deserialize(receiver))
          })
        })
        .finally(() => {
          this.loading = false
        })
    },

    createReceiver: async function (receiver: ReceiverDTO) {
      this.loading = true
      await api
        .post('/api/receivers', JSON.stringify(receiver))
        .then(response => {
          this.receivers.push(ReceiverDTO.deserialize(response.data))
        })
        .finally(() => {
          this.loading = false
        })
    },

    updateReceiver: async function (receiver: ReceiverDTO) {
      this.loading = true
      await api
        .put(`/api/receivers/${receiver.id}`, receiver)
        .then(response => {
          this.receivers = this.receivers.map(r => {
            if (r.id === response.data.id) {
              return receiver
            }
            return r
          })
        })
        .finally(() => {
          this.loading = false
        })
    },

    deleteReceiver: async function (id: number) {
      this.loading = true
      await api
        .delete(`/api/receivers/${id}`)
        .then(() => {
          this.receivers = this.receivers.filter(receiver => receiver.id !== id)
        })
        .finally(() => {
          this.loading = false
        })
    },

    getReceiverById: function (id: number): ReceiverDTO {
      return this.receivers.find(receiver => receiver.id === Number(id)) as ReceiverDTO
    },

    getReceiversWithBankAccounts: async function () {
      await this.getReceivers()
      return this.receivers.filter(receiver => receiver.bankAccounts!.length > 0)
    }
  }
})

export const useReceiverFormStore = defineStore({
  id: 'receiverForm',
  state: () => ({
    receiver: new ReceiverDTO(),
    loading: false
  }),
  actions: {
    setReceiver: function (receiver: ReceiverDTO) {
      this.receiver = receiver
    }
  }
})
