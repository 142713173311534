
import { DocumentDTODocStatutEnum, DocumentDTOTypeDocumentEnum } from '@/models'
import { useDocumentStore } from '@/store/document/document.store'
import moment from 'moment'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DocValidationSection',
  computed: {
    documentStore: function () {
      return useDocumentStore()
    },
    formatExpireDate: function (): string {
      moment.locale(this.$i18n.locale)
      return this.expireDate ? moment(this.expireDate).format('LL') : ''
    },
    minDate: function (): string {
      return new Date(this.addMonths(new Date(Date.now()), 3)).toISOString().substring(0, 10)
    },
    isPassport(): boolean {
      if (this.documentType === this.documentDTOTypeDocumentEnum.PASSPORT) {
        this.setSamePage(true)
        return true
      }
      this.setSamePage(false)
      return false
    }
  },
  beforeMount: function () {
    this.documentStore.getUserDocuments()
  },
  destroyed: function () {
    this.documentStore.reset()
  },
  data: function () {
    return {
      selectedFrontFile: {} as File,
      selectedBackFile: {} as File,
      documentTypeFormValid: false,
      menuExpireDate: false,
      documentType: DocumentDTOTypeDocumentEnum.CANADIANDRIVELICENCE,
      documentStatus: DocumentDTODocStatutEnum,
      documentDTOTypeDocumentEnum: DocumentDTOTypeDocumentEnum,
      expireDate: '',
      showLoader: false,
      documentsType: [
        {
          value: DocumentDTOTypeDocumentEnum.CANADIANDRIVELICENCE,
          text: this.$t('drivingLicense')
        },
        {
          value: DocumentDTOTypeDocumentEnum.PASSPORT,
          text: this.$t('passport')
        },
        {
          value: DocumentDTOTypeDocumentEnum.CANADIANRESIDENTCARD,
          text: this.$t('residentCard')
        },
        {
          value: DocumentDTOTypeDocumentEnum.ODEROFFICIALDOCUMENT,
          text: this.$t('otherDocument')
        }
      ],
      samePage: false
    }
  },
  methods: {
    addMonths: function (date: Date, months: number): number {
      return date.setMonth(date.getMonth() + months)
    },
    setSamePage: function (value: boolean) {
      this.samePage = value
    },
    sendDocument: async function () {
      // Check if the document size is less than 5MB
      if (this.selectedFrontFile.size > 5000000) {
        this.$toast.error(this.$t('documentSizeError'))
        this.showLoader = false
        return
      }

      // Check if the file extension is allowed
      const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i
      if (!allowedExtensions.exec(this.selectedFrontFile.name)) {
        this.$toast.error(this.$t('documentExtensionError'))
        this.showLoader = false
        return
      }

      this.showLoader = true

      await this.documentStore
        .uploadDocument(
          this.selectedFrontFile,
          this.selectedBackFile,
          this.documentType,
          this.expireDate,
          this.samePage
        )
        .then(async () => {
          await this.documentStore.getUserDocuments()
          this.samePage = false
          this.documentType = DocumentDTOTypeDocumentEnum.CANADIANDRIVELICENCE
          this.selectedFrontFile = {} as File
          this.selectedBackFile = {} as File
          this.expireDate = ''
          this.$toast.success(this.$t('documentUploaded'), { timeout: 8000 })
        })
        .catch(error => {
          if (error.response.status === 400) {
            this.$toast.error(this.$t('error400Msg'))
          }
          if (error.response.status === 401) {
            this.$toast.error(this.$t('error401Msg'))
          }
          if (error.response.status === 500) {
            this.$toast.error(this.$t('error500Msg'))
          }
        })
        .finally(() => {
          this.showLoader = false
        })
    }
  }
})
