/* tslint:disable */
/* eslint-disable */
/**
 * Transfert API
 * Transfert API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ClientDTO } from './client-dto';
import { CountryDTO } from './country-dto';
import { ReceiverDTO } from './receiver-dto';
/**
 * 
 * @export
 * @interface PhoneDTO
 */
export interface PhoneDTO {
    /**
     * 
     * @type {ClientDTO}
     * @memberof PhoneDTO
     */
    client?: ClientDTO;
    /**
     * 
     * @type {CountryDTO}
     * @memberof PhoneDTO
     */
    country?: CountryDTO;
    /**
     * 
     * @type {string}
     * @memberof PhoneDTO
     */
    dialCode?: string;
    /**
     * 
     * @type {number}
     * @memberof PhoneDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PhoneDTO
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoneDTO
     */
    phoneOperator?: PhoneDTOPhoneOperatorEnum;
    /**
     * 
     * @type {ReceiverDTO}
     * @memberof PhoneDTO
     */
    receiver?: ReceiverDTO;
}

/**
    * @export
    * @enum {string}
    */
export enum PhoneDTOPhoneOperatorEnum {
    MTNCAMEROON = 'MTNCAMEROON',
    ORANGECAMEROUN = 'ORANGECAMEROUN'
}

