
import * as ExcelJS from 'exceljs'
import { defineComponent } from 'vue'
import { saveAs } from 'file-saver'
import { useTransactionsHistoryStore } from '@/store/transactions/history.store'
import { TransactionDTO, TransactionDTOTransactionStatusEnum, TransactionDTOTransactionTypeEnum } from '@/models'
import { formatDate, formatDateTime, sortListOfTransactionDTOByDateDESC } from '@/helpers/functions'
import { useAccountStore } from '@/store/auth/account.store'

export default defineComponent({
  name: 'ExcelReport',
  computed: {
    transactionsStore: function () {
      return useTransactionsHistoryStore()
    },

    accountStore: function () {
      return useAccountStore()
    }
  },
  methods: {
    createExcel: async function () {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Transactions List')

      // build the header
      await this.buildHeader(workbook, worksheet)

      // build the subheader
      await this.buildSubHeader(workbook, worksheet)

      // build the table
      await this.buildTable(workbook, worksheet)

      // Désactive les lignes de la grid
      worksheet.views = [{ showGridLines: false }]

      // Télécharger le fichier Excel
      workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], { type: 'application/octet-stream' })
        saveAs(blob, `Transactions ${formatDateTime(new Date())}.xlsx`)
      })
    },

    insertImage: async function (workbook: ExcelJS.Workbook, worksheet: ExcelJS.Worksheet) {
      const imageData = await fetch('/img/logo.3f32109f.png').then(response => response.arrayBuffer())
      const imageBase64 = btoa(new Uint8Array(imageData).reduce((data, byte) => data + String.fromCharCode(byte), ''))
      const imageId = workbook.addImage({
        base64: imageBase64,
        extension: 'jpeg'
      })
      worksheet.addImage(imageId, {
        tl: { col: 2.5, row: 1.7 },
        ext: { width: 100, height: 80 }
      })
    },

    buildHeader: async function (workbook: ExcelJS.Workbook, worksheet: ExcelJS.Worksheet) {
      // Ajouter l'image au fichier Excel
      await this.insertImage(workbook, worksheet)

      // Ajouter l'adresse de l'entreprise
      worksheet.mergeCells('E3:G3')
      worksheet.mergeCells('E4:G4')
      worksheet.mergeCells('E5:G5')
      worksheet.getCell('E3').font = { name: 'Roboto', size: 10 }
      worksheet.getCell('E4').font = { name: 'Roboto', size: 10 }
      worksheet.getCell('E5').font = { name: 'Roboto', size: 10 }

      worksheet.getCell('E3:G3').value = '620 RUE LABBÉ LEVIS, QC, CANADA G6K 1W4'
      worksheet.getCell('E4:G4').value = 'Tel: +1 (581) 681-3427'
      worksheet.getCell('E5:G5').value = 'Site: https://www.gtfinance.ca'

      worksheet.mergeCells('N4:Q5')
      worksheet.getCell('N4:Q5').font = { name: 'Roboto', size: 14, bold: true }
      worksheet.getCell('N4:Q5').alignment = { vertical: 'middle', horizontal: 'right' }
      worksheet.getCell('N4:Q5').value = 'ACCOUNT STATEMENT'
    },

    buildSubHeader: async function (workbook: ExcelJS.Workbook, worksheet: ExcelJS.Worksheet) {
      worksheet.mergeCells('C10:D10')
      worksheet.getCell('C10:D10').font = { name: 'Roboto', size: 10 }
      worksheet.getCell('C10:D10').alignment = { vertical: 'middle', horizontal: 'left' }
      worksheet.getCell('C10:D10').value = 'Account Number:'

      worksheet.mergeCells('E10:G10')
      worksheet.getCell('E10:G10').font = { name: 'Roboto', size: 10 }
      worksheet.getCell('E10:G10').alignment = { vertical: 'middle', horizontal: 'left' }
      worksheet.getCell('E10:G10').value = this.accountStore.accountData.id

      worksheet.mergeCells('C11:D11')
      worksheet.getCell('C11:D11').font = { name: 'Roboto', size: 10 }
      worksheet.getCell('C11:D11').alignment = { vertical: 'middle', horizontal: 'left' }
      worksheet.getCell('C11:D11').value = 'Statement Date:'

      worksheet.mergeCells('E11:G11')
      worksheet.getCell('E11:G11').font = { name: 'Roboto', size: 10 }
      worksheet.getCell('E11:G11').alignment = { vertical: 'middle', horizontal: 'left' }
      worksheet.getCell('E11:G11').value = formatDate(new Date())

      worksheet.mergeCells('O9:Q9')
      worksheet.getCell('O9:Q9').font = { name: 'Roboto', size: 12, bold: true }
      worksheet.getCell('O9:Q9').alignment = { vertical: 'middle', horizontal: 'right' }
      worksheet.getCell(
        'O9:Q9'
      ).value = `${this.accountStore.accountData.firstName} ${this.accountStore.accountData.lastName}`

      worksheet.mergeCells('O10:Q10')
      worksheet.getCell('O10:Q10').font = { name: 'Roboto', size: 10 }
      worksheet.getCell('O10:Q10').alignment = { vertical: 'middle', horizontal: 'right' }
      worksheet.getCell('O10:Q10').value = this.accountStore.accountData.email

      worksheet.mergeCells('O11:Q11')
      worksheet.getCell('O11:Q11').font = { name: 'Roboto', size: 10 }
      worksheet.getCell('O11:Q11').alignment = { vertical: 'middle', horizontal: 'right' }
      worksheet.getCell('O11:Q11').value = this.accountStore.accountData.phoneNumber
    },

    buildTable: async function (workbook: ExcelJS.Workbook, worksheet: ExcelJS.Worksheet) {
      const bgHeaderStyle: ExcelJS.Fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF7CC233' },
        bgColor: { argb: 'FF7CC233' }
      }

      const textHeaderStyle: Partial<ExcelJS.Font> = {
        name: 'Roboto',
        size: 10,
        bold: true,
        color: { argb: 'FFFFFFFF' }
      }

      const textBodyStyle: Partial<ExcelJS.Font> = {
        name: 'Roboto',
        size: 10,
        bold: false,
        color: { argb: 'FF000000' }
      }

      // Ajouter l'en-tête du tableau: couleur de fond(##7cc233), couleur de texte(##ffffff), taille de police(10), gras
      worksheet.mergeCells('C16:D16')
      worksheet.getCell('C16:D16').fill = bgHeaderStyle
      worksheet.getCell('C16:D16').font = textHeaderStyle
      worksheet.getCell('C16:D16').alignment = { vertical: 'middle', horizontal: 'left' }
      worksheet.getCell('C16:D16').value = 'Date'

      worksheet.mergeCells('E16:G16')
      worksheet.getCell('E16:G16').fill = bgHeaderStyle
      worksheet.getCell('E16:G16').font = textHeaderStyle
      worksheet.getCell('E16:G16').alignment = { vertical: 'middle', horizontal: 'left' }
      worksheet.getCell('E16:G16').value = 'Beneficiary'

      worksheet.mergeCells('H16:J16')
      worksheet.getCell('H16:J16').fill = bgHeaderStyle
      worksheet.getCell('H16:J16').font = textHeaderStyle
      worksheet.getCell('H16:J16').alignment = { vertical: 'middle', horizontal: 'left' }
      worksheet.getCell('H16:J16').value = 'Payment Mode'

      worksheet.mergeCells('K16')
      worksheet.getCell('K16').fill = bgHeaderStyle
      worksheet.getCell('K16').font = textHeaderStyle
      worksheet.getCell('K16').alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getCell('K16').value = 'Status'

      worksheet.mergeCells('L16:M16')
      worksheet.getCell('L16:M16').fill = bgHeaderStyle
      worksheet.getCell('L16:M16').font = textHeaderStyle
      worksheet.getCell('L16:M16').alignment = { vertical: 'middle', horizontal: 'right' }
      worksheet.getCell('L16:M16').value = 'Amount (CAD)'

      worksheet.mergeCells('N16:O16')
      worksheet.getCell('N16:O16').fill = bgHeaderStyle
      worksheet.getCell('N16:O16').font = textHeaderStyle
      worksheet.getCell('N16:O16').alignment = { vertical: 'middle', horizontal: 'right' }
      worksheet.getCell('N16:O16').value = 'Amount (XAF)'

      worksheet.mergeCells('P16:Q16')
      worksheet.getCell('P16:Q16').fill = bgHeaderStyle
      worksheet.getCell('P16:Q16').font = textHeaderStyle
      worksheet.getCell('P16:Q16').alignment = { vertical: 'middle', horizontal: 'right' }
      worksheet.getCell('P16:Q16').value = 'Rate (XAF/CAD)'

      // Ajouter le contenu du tableau
      let row = 17
      for (const transaction of sortListOfTransactionDTOByDateDESC(this.transactionsStore.transactions as any)) {
        worksheet.mergeCells(`C${row}:D${row}`)
        worksheet.getCell(`C${row}:D${row}`).font = textBodyStyle
        worksheet.getCell(`C${row}:D${row}`).alignment = { vertical: 'middle', horizontal: 'left' }
        worksheet.getCell(`C${row}:D${row}`).value = formatDate(transaction.transactionDate! as any)

        worksheet.mergeCells(`E${row}:G${row}`)
        worksheet.getCell(`E${row}:G${row}`).font = textBodyStyle
        worksheet.getCell(`E${row}:G${row}`).alignment = { vertical: 'middle', horizontal: 'left' }
        worksheet.getCell(`E${row}:G${row}`).value = this.getfullName(transaction)

        worksheet.mergeCells(`H${row}:J${row}`)
        worksheet.getCell(`H${row}:J${row}`).font = textBodyStyle
        worksheet.getCell(`H${row}:J${row}`).alignment = { vertical: 'middle', horizontal: 'left' }
        worksheet.getCell(`H${row}:J${row}`).value = this.paymentMode(transaction)

        worksheet.mergeCells(`K${row}`)
        worksheet.getCell(`K${row}`).font = textBodyStyle
        worksheet.getCell(`K${row}`).alignment = { vertical: 'middle', horizontal: 'center' }
        worksheet.getCell(`K${row}`).value = this.transactionStatus(transaction)

        worksheet.mergeCells(`L${row}:M${row}`)
        worksheet.getCell(`L${row}:M${row}`).font = textBodyStyle
        worksheet.getCell(`L${row}:M${row}`).alignment = { vertical: 'middle', horizontal: 'right' }
        worksheet.getCell(`L${row}:M${row}`).value = transaction.amount

        worksheet.mergeCells(`N${row}:O${row}`)
        worksheet.getCell(`N${row}:O${row}`).font = textBodyStyle
        worksheet.getCell(`N${row}:O${row}`).alignment = { vertical: 'middle', horizontal: 'right' }
        worksheet.getCell(`N${row}:O${row}`).value = transaction.amountXfa

        worksheet.mergeCells(`P${row}:Q${row}`)
        worksheet.getCell(`P${row}:Q${row}`).font = textBodyStyle
        worksheet.getCell(`P${row}:Q${row}`).alignment = { vertical: 'middle', horizontal: 'right' }
        worksheet.getCell(`P${row}:Q${row}`).value = transaction.transactionRate

        row++
      }
    },

    paymentMode: function (transaction: TransactionDTO): string {
      if (transaction.paymentMode === 'MTNMOMO') {
        return 'MTN Mobile Money'
      } else if (transaction.paymentMode === 'CMORANGEMOMO') {
        return 'Orange Money'
      } else {
        return this.getfullName(transaction)
      }
    },
    getfullName: function (transaction: TransactionDTO): string {
      if (transaction.transactionType === TransactionDTOTransactionTypeEnum.BILLPAY) {
        const [, provider] = transaction.paymentMode?.split('_') || []
        return `${provider}`
      }
      return `${transaction.receiver!.firstName} ${transaction.receiver!.lastName}`
    },
    transactionStatus: function (transaction: TransactionDTO): string {
      if (transaction.transactionStatus === TransactionDTOTransactionStatusEnum.PEDDINT) {
        return 'Pending'
      } else if (transaction.transactionStatus === TransactionDTOTransactionStatusEnum.DONE) {
        return 'Success'
      } else if (transaction.transactionStatus === TransactionDTOTransactionStatusEnum.INIT) {
        return 'Initiated'
      } else {
        return 'Failed'
      }
    }
  }
})
