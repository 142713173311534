
import { passwordPattern } from '@/helpers/constants'
import { useAccountStore } from '@/store/auth/account.store'
import { useAuthStore } from '@/store/auth/auth.store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UpdatePwdSection',
  computed: {
    authStore: function () {
      return useAuthStore()
    },
    accountStore: function () {
      return useAccountStore()
    }
  },
  data: function () {
    return {
      oldPasswordRules: [(value: string) => !!value || this.$t('currentPasswordError')],
      newPasswordRules: [
        (value: string) => !!value || this.$t('newPasswordError'),
        (value: string) => passwordPattern.test(value) || this.$t('passwordNotValid')
      ],
      confirmPasswordRules: [(value: string) => !!value || this.$t('confirmPasswordError')],
      updatePasswordValid: false,
      password: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      showLoader: false,
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false
    }
  },
  methods: {
    updatePassword: function () {
      const form = this.$refs.updatePassword as any
      form.validate()
      if (this.updatePasswordValid) {
        this.showLoader = true
        this.authStore
          .updatePassword(this.password.oldPassword, this.password.newPassword)
          .then(() => {
            this.$toast.success(this.$t('passwordUpdated'))
            this.authStore.setReturnUrl(this.$route.name as string)
            this.authStore.logout()
          })
          .catch(error => {
            if (error.response.status === 400) {
              this.$toast.error(this.$t('currentPasswordIncorrect'))
            }
            if (error.response.status === 401) {
              this.$toast.error(this.$t('error401Msg'))
            }
            if (error.response.status === 500) {
              this.$toast.error(this.$t('error500Msg'))
            }
          })
          .finally(() => {
            this.showLoader = false
          })
      }
    },
    confirmPasswordError: function () {
      return this.password.confirmPassword !== this.password.newPassword
    }
  }
})
