/* tslint:disable */
/* eslint-disable */
/**
 * Transfert API
 * Transfert API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SendMoneyRecieverDTO } from './send-money-reciever-dto';
/**
 * 
 * @export
 * @interface SendMoneyDto
 */
export interface SendMoneyDto {
    /**
     * 
     * @type {boolean}
     * @memberof SendMoneyDto
     */
    addReciever?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SendMoneyDto
     */
    amountCDA?: number;
    /**
     * 
     * @type {number}
     * @memberof SendMoneyDto
     */
    amountXFA?: number;
    /**
     * 
     * @type {SendMoneyRecieverDTO}
     * @memberof SendMoneyDto
     */
    receiverDTO?: SendMoneyRecieverDTO;
    /**
     * 
     * @type {number}
     * @memberof SendMoneyDto
     */
    transactionFee?: number;
    /**
     * 
     * @type {number}
     * @memberof SendMoneyDto
     */
    transactionRate?: number;
    /**
     * 
     * @type {string}
     * @memberof SendMoneyDto
     */
    transactionType?: SendMoneyDtoTransactionTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SendMoneyDtoTransactionTypeEnum {
    BANKTRANSFERE = 'BANKTRANSFERE',
    BILLPAY = 'BILLPAY',
    MOBILETRANSFERE = 'MOBILETRANSFERE'
}

