
import { DeviseType } from '@/helpers/enum'
import { useAccountStore } from '@/store/auth/account.store'
import { useRateStore } from '@/store/xe/rate.store'
import { defineComponent } from 'vue'
import { formatAmount } from '@/helpers/functions'

export default defineComponent({
  name: 'BalanceAccount',
  beforeMount: function () {
    this.rateStore.fetchRate()
  },
  computed: {
    accountStore: function () {
      return useAccountStore()
    },
    rateStore: function () {
      return useRateStore()
    }
  },
  data: function () {
    return {
      deviseType: DeviseType,
      formatAmount
    }
  }
})
