import { render, staticRenderFns } from "./TermsCondition.vue?vue&type=template&id=50160245&scoped=true"
import script from "./TermsCondition.vue?vue&type=script&lang=ts"
export * from "./TermsCondition.vue?vue&type=script&lang=ts"
import style0 from "./TermsCondition.vue?vue&type=style&index=0&id=50160245&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50160245",
  null
  
)

export default component.exports