
import config from '@/helpers/config'
import { linkAppStore, linkPlayStore } from '@/helpers/constants'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Footer',
  computed: {
    config: function () {
      return config
    },
    window: function () {
      return window
    }
  },
  setup() {
    return {
      linkAppStore,
      linkPlayStore
    }
  },
  methods: {
    whatsapp: function () {
      window.open(`https://wa.me/${process.env.VUE_APP_WHATSAPP_PHONE}`)
    },
    xe: function () {
      window.open(`https://www.xe.com/`)
    },
    naabi: function () {
      window.open(`https://naabifinance.com/`)
    }
  }
  // components: { AccreditedBy }
})
