
import { defineComponent } from 'vue'
import BalanceAccount from '@/components/dashboard/BalanceAccount.vue'
import Countries from '../../../assets/files/countries.json'
import { MiniAccountBankDTO, MiniPhoneDTO, MiniPhoneDTOPhoneOperatorEnum, ReceiverDTO } from '@/models'
import { useReceiverStore } from '@/store/receiver/receiver.store'
import { useMobileStore } from '@/store/transactions/mobile.store'
import { DeviseType } from '@/helpers/enum'
import { useRateStore } from '@/store/xe/rate.store'

export default defineComponent({
  name: 'BankDeposit',
  components: {
    BalanceAccount
  },
  beforeMount: function () {
    this.receiverStore.getReceiversWithBankAccounts()
  },
  computed: {
    receiverStore: function () {
      return useReceiverStore()
    },
    mobileStore: function () {
      return useMobileStore()
    },
    rateStore: function () {
      return useRateStore()
    }
  },
  destroyed: function () {
    this.receiverStore.receiver = null
    this.mobileStore.setAmountXAF(1000)
  },
  data: function () {
    return {
      countries: Countries,
      selectedCountry: { name: 'Cameroon', dial_code: '+237', code: 'CM', flag: '🇨🇲' },
      existingReceiver: false,
      deviseType: DeviseType,
      receiveName: '',
      bankAccount: {} as MiniAccountBankDTO,
      showContactList: false,
      addRecipient: false,
      phoneNumber: null as unknown as number,
      phoneOperator: MiniPhoneDTOPhoneOperatorEnum
    }
  },
  methods: {
    receiverSelected: function (receiverDto: ReceiverDTO) {
      if (receiverDto != null) {
        this.existingReceiver = true
        this.addRecipient = false
        this.receiveName = `${receiverDto.firstName} ${receiverDto.lastName}`

        // If receiver has more than one phone number, show list of contacts
        if (receiverDto.phones?.length! > 1) {
          this.showContactList = true
        } else {
          this.changeReceiver(receiverDto)
        }

        // If receiver has an account bank, set it as default account else set empty account
        if (receiverDto.bankAccounts?.length! > 0) {
          this.bankAccount = receiverDto.bankAccounts![0]
        } else {
          this.bankAccount = {} as MiniAccountBankDTO
        }
      } else {
        this.receiveName = ''
        this.phoneNumber = null as unknown as number
        this.bankAccount = {} as MiniAccountBankDTO
        this.selectedCountry = { name: 'Cameroon', dial_code: '+237', code: 'CM', flag: '🇨🇲' }
        this.existingReceiver = false
        this.addRecipient = true
      }
    },

    // Change fields when receiver is selected
    changeReceiver: function (receiverDto: ReceiverDTO) {
      this.selectedCountry = this.countries.find(
        country => country.dial_code === '+' + receiverDto.phones![0].dialCode!
      )!
      this.phoneNumber = receiverDto.phones![0].phoneNumber! as unknown as number
    },

    // Change fields when contact is selected
    selectedContact: function (contact: MiniPhoneDTO) {
      this.selectedCountry = this.countries.find(country => country.dial_code === '+' + contact.dialCode)!
      this.phoneNumber = contact.phoneNumber as unknown as number
      this.showContactList = false
    }
  }
})
