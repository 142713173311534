
import BalanceAccount from '@/components/dashboard/BalanceAccount.vue'
import { DeviseType } from '@/helpers/enum'
import { useAccountStore } from '@/store/auth/account.store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CreditAccount',
  components: {
    BalanceAccount
  },
  computed: {
    accountStore: function () {
      return useAccountStore()
    },
    email: function (): string {
      return process.env.VUE_APP_INTERACT_EMAIL as string
    },
    answer: function (): string {
      return this.accountStore.accountData.interacPwd ?? (process.env.VUE_APP_INTERACT_ANSWER as string)
    }
  },
  data() {
    return {
      amount: 0,
      amountRules: [
        (v: number) => !!v || 'Amount is required',
        (v: number) => v > 5 || 'Amount must be greater than 5'
      ],
      isValid: false,
      deviseType: DeviseType
    }
  },
  methods: {
    requestMoney() {
      console.log('request money')
    },
    async copyText(text: string) {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text)
        this.$toast.info(this.$t('textCopied'))
      }
    }
  }
})
