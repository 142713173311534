
import { defineComponent } from 'vue'
import InfoPersoSection from '@/components/dashboard/profile/InfoPersoSection.vue'
import DocValidationSection from '@/components/dashboard/profile/DocValidationSection.vue'
import UpdatePwdSection from '@/components/dashboard/profile/UpdatePwdSection.vue'
import { useAccountStore } from '@/store/auth/account.store'
import EditAddressSection from '@/components/dashboard/profile/EditAddressSection.vue'
import { AdminUserDTOGenderEnum } from '@/models'

export default defineComponent({
  name: 'UserProfile',
  components: {
    InfoPersoSection,
    DocValidationSection,
    UpdatePwdSection,
    EditAddressSection
  },
  computed: {
    accountStore: function () {
      return useAccountStore()
    }
  },
  data: function () {
    return {
      adminUserDTOGenderEnum: AdminUserDTOGenderEnum
    }
  }
})
