
import { AdminUserDTOGenderEnum } from '@/models'
import { useAccountStore } from '@/store/auth/account.store'
import { useAuthStore } from '@/store/auth/auth.store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Header',
  computed: {
    accountStore: function () {
      return useAccountStore()
    },
    authStore: function () {
      return useAuthStore()
    }
  },
  data: () => ({
    drawer: false,
    loading: false,
    adminUserDTOGenderEnum: AdminUserDTOGenderEnum
  }),
  methods: {
    formatName: function () {
      let fullName = this.accountStore.accountData.firstName + ' ' + this.accountStore.accountData.lastName
      if (fullName.length > 18) {
        fullName = fullName.substring(0, 18) + '...'
      }
      return fullName
    },
    logout: function () {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.authStore.logout()
      }, 2000)
    }
  }
})
