/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LocalDate } from './local-date'
/**
 *
 * @export
 * @interface Bill
 */
export interface Bill {
  /**
   *
   * @type {string}
   * @memberof Bill
   */
  billType?: BillBillTypeEnum
  /**
   *
   * @type {number}
   * @memberof Bill
   */
  penaltyAmount?: number
  /**
   *
   * @type {number}
   * @memberof Bill
   */
  payOrder?: number
  /**
   *
   * @type {string}
   * @memberof Bill
   */
  payItemId?: string
  /**
   *
   * @type {string}
   * @memberof Bill
   */
  payItemDescr?: string
  /**
   *
   * @type {string}
   * @memberof Bill
   */
  serviceNumber?: string
  /**
   *
   * @type {number}
   * @memberof Bill
   */
  serviceid?: number
  /**
   *
   * @type {string}
   * @memberof Bill
   */
  merchant?: string
  /**
   *
   * @type {string}
   * @memberof Bill
   */
  amountType?: BillAmountTypeEnum
  /**
   *
   * @type {string}
   * @memberof Bill
   */
  localCur?: string
  /**
   *
   * @type {number}
   * @memberof Bill
   */
  amountLocalCur?: number
  /**
   *
   * @type {string}
   * @memberof Bill
   */
  billNumber?: string
  /**
   *
   * @type {string}
   * @memberof Bill
   */
  customerNumber?: string
  /**
   *
   * @type {string}
   * @memberof Bill
   */
  billMonth?: string
  /**
   *
   * @type {string}
   * @memberof Bill
   */
  billYear?: string
  /**
   *
   * @type {LocalDate}
   * @memberof Bill
   */
  billDate?: LocalDate
  /**
   *
   * @type {LocalDate}
   * @memberof Bill
   */
  billDueDate?: LocalDate
  /**
   *
   * @type {string}
   * @memberof Bill
   */
  optStrg?: string
  /**
   *
   * @type {number}
   * @memberof Bill
   */
  optNmb?: number
}

/**
 * @export
 * @enum {string}
 */
export enum BillBillTypeEnum {
  REGULAR = 'REGULAR',
  OVERDUE = 'OVERDUE'
}
/**
 * @export
 * @enum {string}
 */
export enum BillAmountTypeEnum {
  FIXED = 'FIXED',
  CUSTOM = 'CUSTOM',
  PARTIAL = 'PARTIAL',
  OVERPAY = 'OVERPAY'
}

/**
 * @export
 * @class Bill
 */
export class Bill implements Bill {
  constructor(data?: Bill) {
    Object.assign(this, data)
  }

  /**
   *
   * @param data
   * @returns
   */
  public static deserialize(data: any): Bill {
    return new Bill({
      billType: data.billType,
      penaltyAmount: data.penaltyAmount,
      payOrder: data.payOrder,
      payItemId: data.payItemId,
      payItemDescr: data.payItemDescr,
      serviceNumber: data.serviceNumber,
      serviceid: data.serviceid,
      merchant: data.merchant,
      amountType: data.amountType,
      localCur: data.localCur,
      amountLocalCur: data.amountLocalCur,
      billNumber: data.billNumber,
      customerNumber: data.customerNumber,
      billMonth: data.billMonth,
      billYear: data.billYear,
      billDate: data.billDate,
      billDueDate: data.billDueDate,
      optStrg: data.optStrg,
      optNmb: data.optNmb
    })
  }
}
