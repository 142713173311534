/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ClientDTO } from './client-dto';
/**
 * 
 * @export
 * @interface GTWalletDTO
 */
export interface GTWalletDTO {
    /**
     * 
     * @type {number}
     * @memberof GTWalletDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof GTWalletDTO
     */
    operationAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof GTWalletDTO
     */
    balance?: number;
    /**
     * 
     * @type {string}
     * @memberof GTWalletDTO
     */
    operationDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof GTWalletDTO
     */
    operation?: GTWalletDTOOperationEnum;
    /**
     * 
     * @type {Date}
     * @memberof GTWalletDTO
     */
    createdDate?: Date;
    /**
     * 
     * @type {ClientDTO}
     * @memberof GTWalletDTO
     */
    client?: ClientDTO;
    /**
     * 
     * @type {string}
     * @memberof GTWalletDTO
     */
    referralCode?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GTWalletDTOOperationEnum {
    DEBIT = 'DEBIT',
    CREDITE = 'CREDITE'
}

