/* tslint:disable */
/* eslint-disable */
/**
 * Transfert API
 * Transfert API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Client } from './client';
/**
 * 
 * @export
 * @interface GTWallet
 */
export interface GTWallet {
    /**
     * 
     * @type {number}
     * @memberof GTWallet
     */
    balance?: number;
    /**
     * 
     * @type {Client}
     * @memberof GTWallet
     */
    client?: Client;
    /**
     * 
     * @type {number}
     * @memberof GTWallet
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GTWallet
     */
    operation?: GTWalletOperationEnum;
    /**
     * 
     * @type {number}
     * @memberof GTWallet
     */
    operationAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof GTWallet
     */
    operationDescription?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GTWalletOperationEnum {
    CREDITE = 'CREDITE',
    DEBIT = 'DEBIT'
}

