/* tslint:disable */
/* eslint-disable */
/**
 * Transfert API
 * Transfert API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @interface MiniAccountBankDTO
 */
export interface MiniAccountBankDTO {
  /**
   *
   * @type {number}
   * @memberof MiniAccountBankDTO
   */
  accountKey?: number
  /**
   *
   * @type {number}
   * @memberof MiniAccountBankDTO
   */
  accountNumber?: number
  /**
   *
   * @type {number}
   * @memberof MiniAccountBankDTO
   */
  bankBranch?: number
  /**
   *
   * @type {number}
   * @memberof MiniAccountBankDTO
   */
  bankId?: number
  /**
   *
   * @type {string}
   * @memberof MiniAccountBankDTO
   */
  bankName?: string
  /**
   *
   * @type {number}
   * @memberof MiniAccountBankDTO
   */
  id?: number
}

/**
 * @exports
 * @class MiniAccountBankDTO
 */
export class MiniAccountBankDTO implements MiniAccountBankDTO {
  constructor(data?: MiniAccountBankDTO) {
    Object.assign(this, data)
  }

  /**
   *
   * @param data
   * @returns
   */
  static deserialize(data: any): MiniAccountBankDTO {
    return new MiniAccountBankDTO({
      accountKey: data.accountKey,
      accountNumber: data.accountNumber,
      bankBranch: data.bankBranch,
      bankId: data.bankId,
      bankName: data.bankName,
      id: data.id
    })
  }
}
