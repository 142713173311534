import router from '@/router'
import { useAuthStore } from '@/store/auth/auth.store'
import axios from 'axios'

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  headers: {
    'Content-Type': 'application/json'
  }
})

api.interceptors.request.use(config => {
  if (localStorage.token != null) {
    const { token } = useAuthStore()
    config.headers.Authorization = `Bearer ${token.idToken}`
  }
  return config
})

api.interceptors.response.use(
  response => {
    return response
  },
  error => {
    // Handle error
    if (error.response.status === 401 && router.currentRoute.name !== 'Login') {
      // Remove "accountData" from localStorage
      localStorage.removeItem('accountData')

      // Remove "accessToken" from localStorage
      localStorage.removeItem('token')

      // If 401 response returned from api
      router.push({ name: 'Login' })
    } else {
      return Promise.reject(error)
    }
  }
)

export default api
