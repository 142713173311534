
import { defineComponent } from 'vue'
import { linkAppStore, linkPlayStore } from '@/helpers/constants'

export default defineComponent({
  name: 'QRCodeDownload',
  setup() {
    return {
      linkAppStore,
      linkPlayStore
    }
  },
  methods: {
    openLink(link: string) {
      window.open(link, '_blank')
    }
  }
})
