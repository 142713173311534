
import { DeviseType } from '@/helpers/enum'
import { useMobileStore } from '@/store/transactions/mobile.store'
import { useRateStore } from '@/store/xe/rate.store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SendMoneyForm',
  computed: {
    rateStore: function () {
      return useRateStore()
    },
    mobileStore: function () {
      return useMobileStore()
    }
  },
  data: function () {
    return {
      deviseType: DeviseType
    }
  },
  beforeMount: function () {
    this.rateStore.fetchRate().then(() => this.mobileStore.setAmountCAD(100))
  }
})
