
import { defineComponent } from 'vue'
import AuthHeader from '../../components/Home/header/AuthHeader.vue'
import { useActivateAccountStore } from '@/store/auth/register.store'

export default defineComponent({
  name: 'ActivateAccount',
  components: {
    AuthHeader
  },
  computed: {
    activateStore: function () {
      return useActivateAccountStore()
    }
  },
  data: function () {
    return {
      otp: '',
      loading: false,
      otpRules: [(v: string) => !!v || '', (v: string) => v.length === 5 || ''],
      valid: false
    }
  },
  methods: {
    submit: function () {
      const form = this.$refs.form as any
      form.validate()
      if (this.valid) {
        this.loading = true

        const payload = {
          email: this.$route.params.email,
          activationKey: this.otp
        }

        this.activateStore
          .activateAccount(payload)
          .then(() => {
            this.loading = false
            this.$router.push({ name: 'Login' })
            this.$toast.success(this.$t('accountCreatedAndActivated'))
          })
          .catch((error: any) => {
            this.loading = false
            if (error.response.status === 500) {
              this.$toast.error(this.$t('invalidActivationCode'))
            }
            if (error.response.status === 400) {
              this.$toast.error(this.$t('error400Msg'))
            }
            if (error.response.status === 401) {
              this.$toast.error(this.$t('error401Msg'))
            }
          })
      }
    }
  }
})
