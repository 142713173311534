
import { ReceiverDTO } from '@/models'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ReceiverItem',
  props: {
    receiver: {
      type: ReceiverDTO,
      required: true
    }
  },
  methods: {
    getFirstLetterOfReceiver(receiver: ReceiverDTO) {
      return receiver.firstName.charAt(0).toUpperCase() + receiver.lastName.charAt(0).toUpperCase()
    },
    edit(receiver: ReceiverDTO) {
      this.$emit('edit', receiver)
    },
    selectReceiveToDelete(receiver: ReceiverDTO) {
      this.$emit('delete', receiver)
    },
    sendMoneyTo(receiver: ReceiverDTO) {
      this.$router.push({
        name: 'SendMoney',
        params: {
          receiver: `${receiver.id}`
        }
      })
    }
  }
})
