import api from '@/services/api'
import { defineStore } from 'pinia'

export const useRateStore = defineStore({
  id: 'rate',
  state: () => ({
    currentRate: 0
  }),
  getters: {
    getRate: function (): number {
      return this.currentRate
    }
  },
  actions: {
    fetchRate: async function () {
      return await api.get('/api/xe/current-rate').then(response => {
        this.currentRate = response.data.currentRate
      })
    },

    setCurrentRate: function (rate: number) {
      this.currentRate = rate
    }
  }
})
