/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LocalDate } from './local-date';
/**
 * 
 * @export
 * @interface MavianceBill
 */
export interface MavianceBill {
    /**
     * 
     * @type {string}
     * @memberof MavianceBill
     */
    billType?: MavianceBillBillTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof MavianceBill
     */
    penaltyAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof MavianceBill
     */
    payOrder?: number;
    /**
     * 
     * @type {string}
     * @memberof MavianceBill
     */
    payItemId?: string;
    /**
     * 
     * @type {string}
     * @memberof MavianceBill
     */
    payItemDescr?: string;
    /**
     * 
     * @type {string}
     * @memberof MavianceBill
     */
    serviceNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof MavianceBill
     */
    serviceid?: number;
    /**
     * 
     * @type {string}
     * @memberof MavianceBill
     */
    merchant?: string;
    /**
     * 
     * @type {string}
     * @memberof MavianceBill
     */
    amountType?: MavianceBillAmountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MavianceBill
     */
    localCur?: string;
    /**
     * 
     * @type {number}
     * @memberof MavianceBill
     */
    amountLocalCur?: number;
    /**
     * 
     * @type {string}
     * @memberof MavianceBill
     */
    billNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof MavianceBill
     */
    customerNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof MavianceBill
     */
    billMonth?: string;
    /**
     * 
     * @type {string}
     * @memberof MavianceBill
     */
    billYear?: string;
    /**
     * 
     * @type {LocalDate}
     * @memberof MavianceBill
     */
    billDate?: LocalDate;
    /**
     * 
     * @type {LocalDate}
     * @memberof MavianceBill
     */
    billDueDate?: LocalDate;
    /**
     * 
     * @type {string}
     * @memberof MavianceBill
     */
    optStrg?: string;
    /**
     * 
     * @type {number}
     * @memberof MavianceBill
     */
    optNmb?: number;
    /**
     * 
     * @type {string}
     * @memberof MavianceBill
     */
    quoteId?: string;
    /**
     * 
     * @type {number}
     * @memberof MavianceBill
     */
    priceLocalCur?: number;
    /**
     * 
     * @type {number}
     * @memberof MavianceBill
     */
    priceSystemCur?: number;
    /**
     * 
     * @type {number}
     * @memberof MavianceBill
     */
    billAmount?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum MavianceBillBillTypeEnum {
    REGULAR = 'REGULAR',
    OVERDUE = 'OVERDUE'
}
/**
    * @export
    * @enum {string}
    */
export enum MavianceBillAmountTypeEnum {
    FIXED = 'FIXED',
    CUSTOM = 'CUSTOM',
    PARTIAL = 'PARTIAL',
    OVERPAY = 'OVERPAY'
}

