/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import type { AddressDTO } from './address-dto'
/**
 *
 * @export
 * @interface ManagedUserVM
 */
export interface ManagedUserVM {
  /**
   *
   * @type {number}
   * @memberof ManagedUserVM
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof ManagedUserVM
   */
  login: string
  /**
   *
   * @type {string}
   * @memberof ManagedUserVM
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof ManagedUserVM
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof ManagedUserVM
   */
  email?: string
  /**
   *
   * @type {boolean}
   * @memberof ManagedUserVM
   */
  activated?: boolean
  /**
   *
   * @type {string}
   * @memberof ManagedUserVM
   */
  langKey?: string
  /**
   *
   * @type {Date}
   * @memberof ManagedUserVM
   */
  birthDate: Date
  /**
   *
   * @type {string}
   * @memberof ManagedUserVM
   */
  phoneNumber?: string
  /**
   *
   * @type {string}
   * @memberof ManagedUserVM
   */
  referralCode?: string
  /**
   *
   * @type {AddressDTO}
   * @memberof ManagedUserVM
   */
  address?: AddressDTO
  /**
   *
   * @type {number}
   * @memberof ManagedUserVM
   */
  balance?: number
  /**
   *
   * @type {number}
   * @memberof ManagedUserVM
   */
  remainingAmount?: number
  /**
   *
   * @type {string}
   * @memberof ManagedUserVM
   */
  interacPwd?: string
  /**
   *
   * @type {Array<string>}
   * @memberof ManagedUserVM
   */
  authorities?: Array<string>
  /**
   *
   * @type {string}
   * @memberof ManagedUserVM
   */
  gender?: ManagedUserVMGenderEnum
  /**
   *
   * @type {boolean}
   * @memberof ManagedUserVM
   */
  useConditionActivated?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ManagedUserVM
   */
  identityCheck?: boolean
  /**
   *
   * @type {string}
   * @memberof ManagedUserVM
   */
  password?: string
}

/**
 * @export
 * @enum {string}
 */
export enum ManagedUserVMGenderEnum {
  MAN = 'MAN',
  WOMAN = 'WOMAN',
  OTHER = 'OTHER',
  NOTHING = ''
}
