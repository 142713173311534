
import { defineComponent } from 'vue'
import { useReceiverStore } from '@/store/receiver/receiver.store'
import { MiniPhoneDTOPhoneOperatorEnum } from '@/models'

export default defineComponent({
  name: 'ListContactModal',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    modal: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('close', value)
      }
    },
    receiverStore: function () {
      return useReceiverStore()
    }
  },
  data() {
    return {
      phoneOperator: MiniPhoneDTOPhoneOperatorEnum
    }
  },
  methods: {
    selectedContact(contact) {
      this.$emit('selectedContact', contact)
      this.modal = false
    }
  }
})
