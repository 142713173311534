import { TransactionDTO, TransactionDTOTransactionTypeEnum } from '@/models'
import api from '@/services/api'
import { defineStore } from 'pinia'

export const useTransactionsHistoryStore = defineStore({
  id: 'transactionsHistory',
  state: () => ({
    transactions: [] as TransactionDTO[],
    loading: false,
    pagination: {
      page: 1,
      rowsPerPage: 5
    },
    search: ''
  }),
  getters: {
    getTransactions: function (state) {
      return state.transactions
    },
    displayedItems: function (state) {
      return state.transactions.slice(
        (state.pagination.page - 1) * state.pagination.rowsPerPage,
        state.pagination.page * state.pagination.rowsPerPage
      )
    }
  },
  actions: {
    setTransactions: function (transactions: any[]) {
      this.transactions = transactions
    },

    getAllTransactionsByUser: async function () {
      this.transactions = []
      this.loading = true
      await api
        .get('/api/transactions')
        .then(response => {
          response.data.forEach((transaction: any) => {
            this.transactions.push(TransactionDTO.deserialize(transaction))
          })
        })
        .finally(() => {
          this.loading = false
        })
    },

    getTransactionsByUserAndType: async function (
      transactionType: TransactionDTOTransactionTypeEnum,
      dateRange: string[]
    ) {
      if (dateRange.length > 0) {
        this.getTransactionsByUserAndDateRangeAndType(transactionType, new Date(dateRange[0]), new Date(dateRange[1]))
        return
      }
      this.transactions = []
      this.loading = true
      await api
        .get(`/api/transactions/type/${transactionType}`)
        .then(response => {
          response.data.forEach((transaction: any) => {
            this.transactions.push(TransactionDTO.deserialize(transaction))
          })
        })
        .finally(() => {
          this.loading = false
        })
    },

    getTransactionsByUserAndDateRange: async function (
      startDate: Date,
      endDate: Date,
      chip?: TransactionDTOTransactionTypeEnum
    ) {
      if (chip !== undefined) {
        this.getTransactionsByUserAndDateRangeAndType(chip, startDate, endDate)
        return
      }
      this.transactions = []
      this.loading = true
      await api
        .get(`/api/transactions/date`, {
          params: {
            dateDebut: startDate.toISOString(),
            dateFin: endDate.toISOString()
          }
        })
        .then(response => {
          response.data.forEach((transaction: any) => {
            this.transactions.push(TransactionDTO.deserialize(transaction))
          })
        })
        .finally(() => {
          this.loading = false
        })
    },

    getTransactionsByUserAndDateRangeAndType: async function (
      transactionType: TransactionDTOTransactionTypeEnum,
      startDate: Date,
      endDate: Date
    ) {
      this.transactions = []
      this.loading = true
      await api
        .get(`/api/transactions/type/${transactionType}/date`, {
          params: {
            dateDebut: startDate.toISOString(),
            dateFin: endDate.toISOString()
          }
        })
        .then(response => {
          response.data.forEach((transaction: any) => {
            this.transactions.push(TransactionDTO.deserialize(transaction))
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
})
