export const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,15}$/
export const loginRegex = /^[a-z0-9]{5,20}$/
export const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9-]+\.[a-z]{2,}$/
export const emailRegexNoRequired = /^(|[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,})$/
export const phoneNumberCM = /^(\+237)?(6[5-9]|2[2-3]|3[2-9]|4[2-9]|7[2-9]|8[2-9])[0-9]{7}$/
export const postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/

export const regexOrange = /^(237)?6(?:5[5-9]|9[0-9])[0-9]{6}$/
export const regexMTN = /^(237)?6(?:5[0-4]|7[0-9]|8[0-9])[0-9]{6}$/

export const defaultRefentCode = 'GTFINANCE'

export const linkPlayStore = 'https://play.google.com/store/apps/details?id=ca.gtfinance'
export const linkAppStore = 'https://apps.apple.com/us/app/gt-finance/id6449562346'
