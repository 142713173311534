import { defineStore } from 'pinia'

export const useMenuStore = defineStore({
  id: 'menu',
  state: () => ({
    drawer: false
  }),
  actions: {
    toggleDrawer: function () {
      this.drawer = !this.drawer
    }
  }
})
