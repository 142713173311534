
/* eslint-disable */

import Footer from '@/components/Home/footer/Footer.vue'
import Header from '@/components/Home/header/Header.vue'
import { SendMailData } from '@/models'
import { useContactStore } from '@/store/contacts.store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HomePage',
  components: {
    Header,
    Footer
  },
  data: function () {
    return {
      valid: false,
      loading: false,
      firstname: '',
      lastname: '',
      email: '',
      phoneNumber: '',
      message: '',
      firstnameRules: [(v: string) => !!v || this.$t('firstNameError')],
      lastnameRules: [(v: string) => !!v || this.$t('lastNameError')],
      emailRules: [
        (v: string) => !!v || this.$t('emailError'),
        (v: string) => /.+@.+\..+/.test(v) || this.$t('emailNotValid')
      ],
      phoneNumberRules: [
        (v: string) => !!v || this.$t('phoneNumberError'),
        (v: string) => (v && v.length <= 10) || this.$t('phoneNumberNotValid')
      ],
      messageRules: [
        (v: string) => !!v || this.$t('contactUsMessageError'),
        (v: string) => (v && v.length <= 250) || this.$t('messageMaxLengthError')
      ],
      messageSended: false
    }
  },
  computed: {
    contactStore() {
      return useContactStore()
    }
  },
  methods: {
    submit: async function () {
      const form = this.$refs.form as any
      form.validate()
      if (this.valid) {
        this.loading = true
        const contactMessage: SendMailData = {
          firtsname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          phone: this.phoneNumber,
          message: this.message,
          langKey: this.$i18n.locale.toUpperCase()
        }

        await this.contactStore
          .sendContactMessage(contactMessage)
          .then(() => {
            this.messageSended = true
            this.clean()
          })
          .catch((error: any) => {
            if (error.response.status === 400) {
              this.$toast.error(this.$t('error400Msg'))
            }
            if (error.response.status === 401) {
              this.$toast.error(this.$t('error401Msg'))
            }
            if (error.response.status === 500) {
              this.$toast.error(this.$t('error500Msg'))
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    call: function () {
      window.open(`tel:${process.env.VUE_APP_PHONE_CALL}`)
    },
    whatsapp: function () {
      window.open(`https://wa.me/${process.env.VUE_APP_WHATSAPP_PHONE}`)
    },

    clean() {
      this.firstname = ''
      this.lastname = ''
      this.email = ''
      this.phoneNumber = ''
      this.message = ''
    }
  }
})
