
import Provinces from '@/assets/files/canadian-provinces.json'
import Countries from '@/assets/files/register-countries.json'
import AuthHeader from '@/components/Home/header/AuthHeader.vue'
import { defaultRefentCode, emailRegex, loginRegex, passwordPattern, postalCodeRegex } from '@/helpers/constants'
import { UsersRole } from '@/helpers/enum'
import { AddressDTO, ManagedUserVM, ManagedUserVMGenderEnum } from '@/models'
import { checkEmailStore, checkExistCodeStore, checkLoginStore, checkPhoneStore } from '@/store/auth/checkField.store'
import { useRegisterStore } from '@/store/auth/register.store'
import moment from 'moment'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Register',
  components: {
    AuthHeader
  },
  setup() {
    return {
      Countries
    }
  },
  computed: {
    maxDate: function (): string {
      return new Date(this.subtractYears(new Date(Date.now()), 18)).toISOString().substring(0, 10)
    },
    emailUsedError: function (): string | undefined {
      return this.$t('emailTaken').toString()
    },
    loginUsedError: function (): string | undefined {
      return this.$t('usernameTaken').toString()
    },
    phoneUsedError: function (): string | undefined {
      return this.$t('phoneTaken').toString()
    },
    registerStore: function () {
      return useRegisterStore()
    },
    emailStore: function () {
      return checkEmailStore()
    },
    loginStore: function () {
      return checkLoginStore()
    },
    phoneStore: function () {
      return checkPhoneStore()
    },
    referralStore: function () {
      return checkExistCodeStore()
    },
    formatBirthDate: function (): string {
      moment.locale(this.$i18n.locale)
      return this.date ? moment(this.date).format('LL') : ''
    },
    genders: function (): Array<any> {
      return [
        { text: this.$t('man'), value: ManagedUserVMGenderEnum.MAN },
        { text: this.$t('woman'), value: ManagedUserVMGenderEnum.WOMAN },
        { text: this.$t('other'), value: ManagedUserVMGenderEnum.OTHER }
      ]
    },
    languages: function (): Array<any> {
      return [
        { text: 'English', value: 'EN' },
        { text: 'Français', value: 'FR' }
      ]
    }
  },
  watch: {
    // Capitalize each word of the first name
    firstName: function (val: string) {
      this.firstName = val
        .split(' ')
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ')
    },

    // uppercase the last name
    lastName: function (val: string) {
      this.lastName = val.toUpperCase()
    },

    referentCode: function (val: string) {
      this.referentCode = val.toUpperCase()
    }
  },
  data: function () {
    return {
      step: 1,
      loading: false,
      selectedCountry: 'Canada',
      firstName: '',
      firstNameRules: [(v: string) => !!v || this.$t('firstNameError')],
      lastName: '',
      lastNameRules: [(v: string) => !!v || this.$t('lastNameError')],
      email: '',
      emailRules: [
        (v: string) => !!v || this.$t('emailError'),
        (v: string) => emailRegex.test(v) || this.$t('emailNotValid')
      ],
      login: '',
      loginRules: [
        (v: string) => !!v || this.$t('usernameError'),
        (v: string) => v.length >= 5 || this.$t('usernameError'),
        (v: string) => loginRegex.test(v) || this.$t('userNameNotValid')
      ],
      phoneLength: 10,
      phoneRules: [
        (v: string) => !!v || this.$t('phoneNumberError'),
        (v: string | string[]) => !v || v.length === 10 || this.$t('phoneNumberNotValid')
      ],
      date: '',
      dateRules: [(v: string) => !!v || this.$t('birthDateError')],
      streetNumberRules: [(v: string) => !!v || this.$t('streetNumberError')],
      apartNumberRules: [
        // (v: string) => !!v || this.$t('apartmentNumberError')
      ],
      postalCodeRules: [
        (v: string) => !!v || this.$t('postalCodeError'),
        (v: string) => postalCodeRegex.test(v) || this.$t('postalCodeNotValid')
      ],
      cityRules: [(v: string) => !!v || this.$t('cityError')],
      provinceRules: [(v: string) => !!v || this.$t('provinceError')],
      password: '',
      passwordRules: [
        (v: string) => !!v || this.$t('passwordError'),
        (v: string | string[]) => (v && passwordPattern.test(v as string)) || this.$t('passwordNotValid')
      ],
      confirmPassword: '',
      confirmPasswordRules: [(v: string) => !!v || this.$t('confirmPasswordError')],
      language: this.$i18n.locale.toUpperCase(),
      gender: ManagedUserVMGenderEnum.NOTHING,
      genderRules: [(v: string) => !!v || this.$t('genderError')],
      refCodeRules: [(v: string) => !!v || this.$t('referentCodeError')],
      haveAReferentCode: false,
      referentCode: '',
      terms: false,
      termsRules: [(v: string) => !!v || this.$t('termsAndConditionsError')],
      menu: false,
      showPassword: false,
      showConfirmPassword: false,
      provinces: Provinces,
      address: {
        country: 'Canada',
        appart: ''
      } as AddressDTO
    }
  },
  destroyed: function () {
    this.emailStore.reset()
    this.loginStore.reset()
    this.registerStore.reset()
  },
  methods: {
    subtractYears: function (date: Date, years: number): number {
      return date.setFullYear(date.getFullYear() - years)
    },
    nextStep() {
      const forms = [this.$refs.form1 as any, this.$refs.form2 as any, this.$refs.form3 as any, this.$refs.form4 as any]

      if (this.step < 1 || this.step > 4) return

      const formValidationPassed =
        forms[this.step - 1].validate() &&
        (this.step !== 3 || (!this.emailStore.emailUsed && !this.loginStore.loginUsed)) &&
        (this.step !== 4 || !this.haveAReferentCode || this.referralStore.codeExist)

      if (formValidationPassed) {
        if (this.step === 4) {
          this.loading = true
          this.createAccount()
        } else {
          this.step++
        }
      }
    },
    prevStep: function () {
      if (this.step === 1) {
        this.$router.push({ name: 'HomePage' })
      } else {
        this.step--
      }
    },

    createAccount: function () {
      // Create user object
      const user: ManagedUserVM = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email.toLowerCase(),
        login: this.login,
        phoneNumber: this.registerStore.phone.toString(),
        birthDate: new Date(this.date),
        password: this.password,
        langKey: this.language.toUpperCase(),
        referralCode: this.haveAReferentCode ? 'GTF-' + this.referentCode : defaultRefentCode,
        gender: this.gender,
        activated: false,
        authorities: [UsersRole.ROLE_USER],
        address: this.address
      }

      this.registerStore
        .createAccount(user)
        .then(async () => {
          await this.$router.push({
            name: 'Activation',
            params: { email: this.email }
          })
        })
        .catch((error: any) => {
          if (error.response.status === 400) {
            this.$toast.error(this.$t('error400Msg'))
          }
          if (error.response.status === 401) {
            this.$toast.error(this.$t('error404Msg'))
          }
          if (error.response.status === 500) {
            this.$toast.error(this.$t('error500Msg'))
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    // check if email is already used
    uniqueEmail() {
      this.emailStore
        .checkEmail(this.email)
        .then(resp => {
          if (resp.data === true) {
            this.emailStore.setEmailState(true)
          } else {
            this.emailStore.setEmailState(false)
          }
        })
        .catch(() => {
          this.emailStore.setEmailState(true)
        })
    },

    // check if login is already used
    uniqueLogin() {
      if (!loginRegex.test(this.login)) {
        this.loginStore.setLoginValidState(false)
        return
      }
      this.loginStore.setLoginValidState(true)

      this.loginStore
        .checkLogin(this.login)
        .then(resp => {
          if (resp.data === true) {
            this.loginStore.setLoginState(true)
          } else {
            this.loginStore.setLoginState(false)
          }
        })
        .catch(() => {
          this.loginStore.setLoginState(true)
        })
    },

    // check if phone number is already used
    uniquePhone() {
      if (this.registerStore.phone.toString().length !== 10) {
        return
      }

      this.phoneStore
        .checkPhone(this.registerStore.phone.toString())
        .then(resp => {
          if (resp.data === true) {
            this.phoneStore.setPhoneState(true)
          } else {
            this.phoneStore.setPhoneState(false)
          }
        })
        .catch(() => {
          this.phoneStore.setPhoneState(true)
        })
    },

    checkExistCode() {
      this.referralStore.checkCode(this.referentCode).then(resp => {
        if (resp.data === true) {
          this.referralStore.setCodeState(true)
        } else {
          this.referralStore.setCodeState(false)
        }
      })
    },

    confirmPasswordError: function () {
      return this.confirmPassword !== this.password
    }
  }
})
