/* tslint:disable */
/* eslint-disable */
/**
 * Transfert API
 * Transfert API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ClientDTO } from './client-dto'
import { ReceiverDTO } from './receiver-dto'
/**
 *
 * @export
 * @interface TransactionDTO
 */
export interface TransactionDTO {
  /**
   *
   * @type {number}
   * @memberof TransactionDTO
   */
  amount?: number
  /**
   *
   * @type {number}
   * @memberof TransactionDTO
   */
  amountXfa?: number
  /**
   *
   * @type {ClientDTO}
   * @memberof TransactionDTO
   */
  client?: ClientDTO
  /**
   *
   * @type {number}
   * @memberof TransactionDTO
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof TransactionDTO
   */
  paymentMode?: string
  /**
   *
   * @type {string}
   * @memberof TransactionDTO
   */
  phoneNumber?: string
  /**
   *
   * @type {ReceiverDTO}
   * @memberof TransactionDTO
   */
  receiver?: ReceiverDTO
  /**
   *
   * @type {Date}
   * @memberof TransactionDTO
   */
  transactionDate?: Date
  /**
   *
   * @type {number}
   * @memberof TransactionDTO
   */
  transactionFee?: number
  /**
   *
   * @type {string}
   * @memberof TransactionDTO
   */
  transactionId?: string
  /**
   *
   * @type {string}
   * @memberof TransactionDTO
   */
  transactionInfo?: string
  /**
   *
   * @type {number}
   * @memberof TransactionDTO
   */
  transactionRate?: number
  /**
   *
   * @type {string}
   * @memberof TransactionDTO
   */
  transactionStatus?: TransactionDTOTransactionStatusEnum
  /**
   *
   * @type {string}
   * @memberof TransactionDTO
   */
  transactionType?: TransactionDTOTransactionTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum TransactionDTOTransactionStatusEnum {
  DONE = 'DONE',
  INIT = 'INIT',
  PEDDINT = 'PEDDINT',
  FAILURE = 'FAILURE'
}
/**
 * @export
 * @enum {string}
 */
export enum TransactionDTOTransactionTypeEnum {
  BANKTRANSFERE = 'BANKTRANSFERE',
  BILLPAY = 'BILLPAY',
  MOBILETRANSFERE = 'MOBILETRANSFERE'
}

/**
 * @export
 * @class TransactionDTO
 */
export class TransactionDTO implements TransactionDTO {
  constructor(data?: TransactionDTO) {
    Object.assign(this, data)
  }

  /**
   *
   * @param json
   * @returns
   */
  public static deserialize(json: any): TransactionDTO {
    return new TransactionDTO({
      amount: json.amount,
      amountXfa: json.amountXfa,
      client: json.client ? ClientDTO.deserialize(json.client) : undefined,
      id: json.id,
      paymentMode: json.paymentMode,
      phoneNumber: json.phoneNumber,
      receiver: json.receiver ? ReceiverDTO.deserialize(json.receiver) : undefined,
      transactionDate: json.transactionDate,
      transactionFee: json.transactionFee,
      transactionId: json.transactionId,
      transactionInfo: json.transactionInfo,
      transactionRate: json.transactionRate,
      transactionStatus: json.transactionStatus,
      transactionType: json.transactionType
    })
  }
}
