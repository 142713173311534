import { TransactionDTO } from '@/models/transaction-dto'
import moment from 'moment'

/**
 * Format a date and time to DD/MM/YYYY - HH:mm
 * @param date
 * @returns
 */
export function formatDateTime(date: Date): string {
  return moment(date).format('DD/MM/YYYY - HH:mm')
}

/**
 * Format a date to DD/MM/YYYY
 * @param date
 * @returns
 */
export function formatDate(date?: Date): string {
  return moment(date).format('DD/MM/YYYY')
}

/**
 * Format a date like this: 01 Jan. 2021
 * @param date
 * @returns Formatted date
 */
export function formatDateWithMonthName(date?: Date): string {
  return moment(date).format('DD MMM. YYYY')
}

/**
 * Sort a list of TransactionDTO by date DESC
 * @param list
 * @returns
 */
export function sortListOfTransactionDTOByDateDESC(list: TransactionDTO[]): TransactionDTO[] {
  return list.sort((a: TransactionDTO, b: TransactionDTO) => {
    if (a.transactionDate! === b.transactionDate!) {
      return 0
    }

    return a.transactionDate! < b.transactionDate! ? 1 : -1
  })
}

/**
 * @description Check if user is logged in
 * @returns
 */
export function checkLoggedIn(): boolean {
  const token = localStorage.getItem('token')
  return !!token
}

/**
 *
 * @param value Amount to format
 * @returns Amount formated
 */
export function formatAmount(value: string | number): string {
  if (value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }
  return `0.00`
}

/**
 * @description get the app URL: ie. http://localhost:8080
 * @returns App URL
 */
export function getAppBaseUrl(): string {
  return window.location.origin
}
