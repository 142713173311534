
import { defineComponent } from 'vue'
import BalanceAccount from '@/components/dashboard/BalanceAccount.vue'
import { useTransactionsHistoryStore } from '@/store/transactions/history.store'
import { DeviseType } from '@/helpers/enum'
import { formatDateWithMonthName, sortListOfTransactionDTOByDateDESC } from '@/helpers/functions'
import { TransactionDTOTransactionTypeEnum } from '@/models'
import PdfReport from '@/components/dashboard/report/PdfReport.vue'
import ExcelReport from '@/components/dashboard/report/ExcelReport.vue'
import TransactionItem from '@/components/dashboard/transactions/TransactionItem.vue'

export default defineComponent({
  name: 'TransactionsHistory',
  components: {
    BalanceAccount,
    PdfReport,
    ExcelReport,
    TransactionItem
  },
  computed: {
    transactionsStore: function () {
      return useTransactionsHistoryStore()
    },
    sortTransactions: function () {
      return sortListOfTransactionDTOByDateDESC
    },
    formatRangeDate: function (): string {
      if (this.dateRange.length < 2) {
        return ''
      }
      const selectedDate = this.dateRange

      if (new Date(selectedDate[0]) > new Date(selectedDate[1])) {
        const temp = selectedDate[0]
        selectedDate[0] = selectedDate[1]
        selectedDate[1] = temp
      }

      return `${formatDateWithMonthName(new Date(selectedDate[0]))} - ${formatDateWithMonthName(
        new Date(selectedDate[1])
      )}`
    }
  },
  data: function () {
    return {
      menu: false,
      dateRange: [] as string[],
      search: '',
      deviseType: DeviseType,
      chips: undefined as unknown as TransactionDTOTransactionTypeEnum,
      transactionType: TransactionDTOTransactionTypeEnum
    }
  },
  beforeMount() {
    this.transactionsStore.getAllTransactionsByUser()
  },
  watch: {
    date: function () {
      if (this.dateRange === null) {
        this.dateRange = [] as string[]
      }
    }
  },
  methods: {
    transactionByFilterChip: async function () {
      if (!this.chips) {
        await this.transactionByDateRange()
        return
      }
      this.transactionsStore.getTransactionsByUserAndType(this.chips, this.dateRange)
    },
    transactionByDateRange: async function () {
      this.menu = false
      if (this.dateRange.length === 0) {
        await this.transactionsStore.getAllTransactionsByUser()
        return
      }

      // Permute date if date[0] > date[1]
      if (new Date(this.dateRange[0]) > new Date(this.dateRange[1])) {
        const temp = this.dateRange[0]
        this.dateRange[0] = this.dateRange[1]
        this.dateRange[1] = temp
      }

      await this.transactionsStore.getTransactionsByUserAndDateRange(
        new Date(this.dateRange[0]),
        new Date(new Date(this.dateRange[1]).setHours(23, 59, 59)),
        this.chips
      )
    },
    clearDateRange: async function () {
      this.dateRange = [] as string[]
      this.menu = false
      await this.transactionByFilterChip()
    }
  }
})
