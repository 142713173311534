import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import AboutUs from '@/views/About.vue'
import ActivationPage from '@/views/auth/ActivateAccount.vue'
import ForgotPassword from '@/views/auth/ForgotPassword.vue'
import LoginPage from '@/views/auth/Login.vue'
import RegisterPage from '@/views/auth/Register.vue'
import ResetPassword from '@/views/auth/ResetPassword.vue'
import ContactsUs from '@/views/ContactsUs.vue'
import HomePage from '@/views/HomePage.vue'

import config from '@/helpers/config'
import Dashboard from '@/views/Dashboard.vue'
import Settings from '@/views/dashboard/profile/Settings.vue'
import UserProfile from '@/views/dashboard/profile/UserProfile.vue'
import ReceiversList from '@/views/dashboard/receivers/ReceiversList.vue'
import BankDeposit from '@/views/dashboard/services/BankDeposit.vue'
import CreditAccount from '@/views/dashboard/services/CreditAccount.vue'
import PayBill from '@/views/dashboard/services/PayBill.vue'
import SendMoney from '@/views/dashboard/services/SendMoney.vue'
import TransactionsHistory from '@/views/dashboard/services/TransactionsHistory.vue'
import PrivacyPolicyVue from '@/views/privacy/PrivacyPolicy.vue'
import TermsAndConditions from '@/views/privacy/TermsCondition.vue'
import QRCodeDownload from '@/views/QRCodeDownload.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/app/download',
    name: 'QRCodeDownload',
    component: QRCodeDownload
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterPage
  },
  {
    path: '/activation/:email',
    name: 'Activation',
    component: ActivationPage
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/contact-us',
    name: 'ContactsUs',
    component: ContactsUs
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicyVue
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { authRequired: true },
    children: [
      {
        path: 'send-money',
        name: 'SendMoney',
        meta: { authRequired: true },
        component: SendMoney
      },
      {
        path: 'pay-bills',
        name: 'PayBill',
        meta: { authRequired: true },
        component: PayBill
      },
      {
        path: 'profile',
        name: 'Profile',
        meta: { authRequired: true },
        component: UserProfile
      },
      {
        path: 'transactions-history',
        name: 'TransactionsHistory',
        meta: { authRequired: true },
        component: TransactionsHistory
      },
      {
        path: 'receivers',
        name: 'Receivers',
        meta: { authRequired: true },
        component: ReceiversList
      },
      {
        path: 'credit-account',
        name: 'CreditAccount',
        meta: { authRequired: true },
        component: CreditAccount
      },
      {
        path: 'bank-deposit',
        name: 'BankDeposit',
        meta: { authRequired: true },
        component: BankDeposit
      },
      {
        path: 'settings',
        name: 'Settings',
        meta: { authRequired: true },
        component: Settings
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, _, next) => {
  if (to.meta?.authRequired && !config.isLoggedIn) {
    config.returnUrl = to.name as string
    return next({ name: 'Login' })
  }
  return next()
})

export default router
