/* tslint:disable */
/* eslint-disable */
/**
 * Transfert API
 * Transfert API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Document } from './document';
import { GTWallet } from './gtwallet';
import { Phone } from './phone';
import { Transaction } from './transaction';
/**
 * 
 * @export
 * @interface Client
 */
export interface Client {
    /**
     * 
     * @type {Date}
     * @memberof Client
     */
    birthDate: Date;
    /**
     * 
     * @type {Array<Document>}
     * @memberof Client
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    firstName: string;
    /**
     * 
     * @type {Array<GTWallet>}
     * @memberof Client
     */
    gTWallet?: Array<GTWallet>;
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Client
     */
    identityCheck?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    language?: ClientLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    lastName: string;
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    limite?: number;
    /**
     * 
     * @type {Phone}
     * @memberof Client
     */
    phone?: Phone;
    /**
     * 
     * @type {Array<Transaction>}
     * @memberof Client
     */
    transactions?: Array<Transaction>;
}

/**
    * @export
    * @enum {string}
    */
export enum ClientLanguageEnum {
    EN = 'EN',
    FR = 'FR'
}

