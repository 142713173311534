/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface TransactionOperationFilterDTO
 */
export interface TransactionOperationFilterDTO {
    /**
     * 
     * @type {string}
     * @memberof TransactionOperationFilterDTO
     */
    clientEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionOperationFilterDTO
     */
    transactionStatus?: TransactionOperationFilterDTOTransactionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TransactionOperationFilterDTO
     */
    transactionType?: TransactionOperationFilterDTOTransactionTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TransactionOperationFilterDTOTransactionStatusEnum {
  INIT = 'INIT',
  PEDDINT = 'PEDDINT',
  DONE = 'DONE',
  FAILURE = 'FAILURE'
}
/**
    * @export
    * @enum {string}
    */
export enum TransactionOperationFilterDTOTransactionTypeEnum {
    MOBILETRANSFERE = 'MOBILETRANSFERE',
    BANKTRANSFERE = 'BANKTRANSFERE',
    BILLPAY = 'BILLPAY'
}

