
import { defineComponent } from 'vue'
import BalanceAccount from '@/components/dashboard/BalanceAccount.vue'
import { useReceiverStore, useReceiverFormStore } from '@/store/receiver/receiver.store'
import { ReceiverDTO } from '@/models'
import ReceiverFormVue from '@/components/dashboard/receiver/ReceiverForm.vue'
import ReceiverItem from '@/components/dashboard/receiver/ReceiverItem.vue'

export default defineComponent({
  name: 'ReceiversList',
  components: {
    BalanceAccount,
    ReceiverForm: ReceiverFormVue,
    ReceiverItem
  },
  beforeMount() {
    this.receiversStore.getReceivers()
  },
  computed: {
    receiversStore() {
      return useReceiverStore()
    },
    receiverFormStore() {
      return useReceiverFormStore()
    }
  },
  data: function () {
    return {
      search: '',
      confirmDeletingDialog: false,
      receiverToDelete: {} as ReceiverDTO,
      showReceiverForm: false,
      formMode: 'add'
    }
  },
  methods: {
    createUser() {
      this.formMode = 'add'
      this.receiverFormStore.receiver = new ReceiverDTO()
      this.showReceiverForm = true
    },
    edit(item: ReceiverDTO) {
      this.formMode = 'edit'
      this.receiverFormStore.receiver = item
      this.showReceiverForm = true
    },
    async saveUser(user: ReceiverDTO) {
      await this.receiversStore.createReceiver(user).then(() => {
        this.showReceiverForm = false
        this.receiversStore.getReceivers()
        this.$toast.success(this.$t('recipientAdded'))
      })
    },
    async updateUser(user: ReceiverDTO) {
      await this.receiversStore.updateReceiver(user).then(() => {
        this.showReceiverForm = false
        this.receiversStore.getReceivers()
        this.$toast.success(this.$t('recipientUpdated'))
      })
    },
    cancelReceiverForm() {
      this.showReceiverForm = false
    },
    selectReceiveToDelete(item: ReceiverDTO) {
      this.confirmDeletingDialog = true
      this.receiverToDelete = item
    },
    async deleteReceiver() {
      await this.receiversStore
        .deleteReceiver(this.receiverToDelete.id!)
        .catch(() => {
          this.$toast.error(this.$t('deletingRecipientError'))
        })
        .finally(() => {
          this.confirmDeletingDialog = false
        })
    }
  }
})
