/* tslint:disable */
/* eslint-disable */
/**
 * Transfert API
 * Transfert API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Client } from './client';
/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {Client}
     * @memberof Document
     */
    client?: Client;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    docCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    docStatut?: DocumentDocStatutEnum;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    typeDocument?: DocumentTypeDocumentEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentDocStatutEnum {
    ADDED = 'ADDED',
    MISSING = 'MISSING',
    VALIDE = 'VALIDE'
}
/**
    * @export
    * @enum {string}
    */
export enum DocumentTypeDocumentEnum {
    CANADIANDRIVELICENCE = 'CANADIANDRIVELICENCE',
    CANADIANRESIDENTCARD = 'CANADIANRESIDENTCARD',
    ODEROFFICIALDOCUMENT = 'ODEROFFICIALDOCUMENT',
    PASSPORT = 'PASSPORT'
}

