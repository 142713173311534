// Description: This file contains all the enums used in the application

/**
 * This enum is used to define the different roles of the users
 */
export enum UsersRole {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_AUDIT = 'ROLE_AUDIT',
  ROLE_USER = 'ROLE_USER',
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN'
}

/**
 * This enum is used to define the different status of the transactions
 */
export enum TransactionStatus {
  PENDING = 'PENDING',
  DONE = 'DONE',
  FAILURE = 'FAILURE',
  INIT = 'INIT'
}

/**
 * This enum is used to define the different types of the transactions
 */
export enum TransactionType {
  BANKTRANSFERE = 'BANKTRANSFERE',
  BILLPAY = 'BILLPAY',
  MOBILETRANSFERE = 'MOBILETRANSFERE'
}

/**
 * This enum is used to define the different types of the devises
 */
export enum DeviseType {
  CAD = 'CAD',
  XAF = 'XAF'
}

export enum PaymentMode {
  CMORANGEMOMO = 'CMORANGEMOMO',
  MTNMOMO = 'MTNMOMO'
}

export enum ServicesMerchant {
  CMORANGEMOMO = 'CMORANGEMOMO',
  MTNMOMO = 'MTNMOMO',
  ENEO = 'ENEO',
  CAMWATER = 'CAMWATER',
  CMDGI = 'CMDGI',
  CMDOUANES = 'CMDOUANES'
}
