/* tslint:disable */
/* eslint-disable */
/**
 * Transfert API
 * Transfert API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @interface JWTToken
 */
export interface JWTToken {
  /**
   *
   * @type {string}
   * @memberof JWTToken
   */
  idToken?: string
}

export class JWTToken implements JWTToken {
  constructor(init?: Partial<JWTToken>) {
    Object.assign(this, init)
  }

  /**
   *
   * @param json
   * @returns
   */
  public static deserialize(json: any): JWTToken {
    return new JWTToken({
      idToken: json.id_token
    })
  }
}
