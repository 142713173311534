
import MenuMobile from '@/components/Home/header/MenuMobile.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',
  components: {
    MenuMobile
  },
  beforeMount() {
    document.title = 'GT Finance Inc.'
  }
})
