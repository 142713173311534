/* tslint:disable */
/* eslint-disable */
/**
 * Transfert API
 * Transfert API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CountryDTO } from './country-dto';
import { TransactionDTO } from './transaction-dto';
/**
 * 
 * @export
 * @interface BillDTO
 */
export interface BillDTO {
    /**
     * 
     * @type {number}
     * @memberof BillDTO
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof BillDTO
     */
    billNumber?: string;
    /**
     * 
     * @type {CountryDTO}
     * @memberof BillDTO
     */
    country?: CountryDTO;
    /**
     * 
     * @type {number}
     * @memberof BillDTO
     */
    id?: number;
    /**
     * 
     * @type {TransactionDTO}
     * @memberof BillDTO
     */
    transaction?: TransactionDTO;
    /**
     * 
     * @type {string}
     * @memberof BillDTO
     */
    typeService?: BillDTOTypeServiceEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum BillDTOTypeServiceEnum {
    CAMWATER = 'CAMWATER',
    CANALPLUS = 'CANALPLUS',
    CMDGI = 'CMDGI',
    CMDOUANES = 'CMDOUANES',
    ENEO = 'ENEO'
}

