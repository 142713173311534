/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface UiConfiguration
 */
export interface UiConfiguration {
    /**
     * 
     * @type {boolean}
     * @memberof UiConfiguration
     */
    deepLinking?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UiConfiguration
     */
    displayOperationId?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UiConfiguration
     */
    defaultModelsExpandDepth?: number;
    /**
     * 
     * @type {number}
     * @memberof UiConfiguration
     */
    defaultModelExpandDepth?: number;
    /**
     * 
     * @type {string}
     * @memberof UiConfiguration
     */
    defaultModelRendering?: UiConfigurationDefaultModelRenderingEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UiConfiguration
     */
    displayRequestDuration?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UiConfiguration
     */
    docExpansion?: UiConfigurationDocExpansionEnum;
    /**
     * 
     * @type {any}
     * @memberof UiConfiguration
     */
    filter?: any;
    /**
     * 
     * @type {number}
     * @memberof UiConfiguration
     */
    maxDisplayedTags?: number;
    /**
     * 
     * @type {string}
     * @memberof UiConfiguration
     */
    operationsSorter?: UiConfigurationOperationsSorterEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UiConfiguration
     */
    showExtensions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UiConfiguration
     */
    showCommonExtensions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UiConfiguration
     */
    tagsSorter?: UiConfigurationTagsSorterEnum;
    /**
     * 
     * @type {string}
     * @memberof UiConfiguration
     */
    validatorUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UiConfiguration
     */
    supportedSubmitMethods?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UiConfiguration
     */
    swaggerBaseUiUrl?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UiConfigurationDefaultModelRenderingEnum {
    Example = 'example',
    Model = 'model'
}
/**
    * @export
    * @enum {string}
    */
export enum UiConfigurationDocExpansionEnum {
    None = 'none',
    List = 'list',
    Full = 'full'
}
/**
    * @export
    * @enum {string}
    */
export enum UiConfigurationOperationsSorterEnum {
    Alpha = 'alpha',
    Method = 'method'
}
/**
    * @export
    * @enum {string}
    */
export enum UiConfigurationTagsSorterEnum {
    Alpha = 'alpha'
}

