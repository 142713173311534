/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import type { MavianceSercieDTO } from './maviance-sercie-dto'
/**
 *
 * @export
 * @interface MServiceHistriesDTO
 */
export interface MServiceHistriesDTO {
  /**
   *
   * @type {number}
   * @memberof MServiceHistriesDTO
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof MServiceHistriesDTO
   */
  serviceStatus?: MServiceHistriesDTOServiceStatusEnum
  /**
   *
   * @type {MavianceSercieDTO}
   * @memberof MServiceHistriesDTO
   */
  mavianceSercie?: MavianceSercieDTO
}

/**
 * @export
 * @enum {string}
 */
export enum MServiceHistriesDTOServiceStatusEnum {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE'
}
