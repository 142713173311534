
import config from '@/helpers/config'
import { JWTToken, LoginVM } from '@/models'
import { useAccountStore } from '@/store/auth/account.store'
import { useAuthStore } from '@/store/auth/auth.store'
import { defineComponent } from 'vue'
import AuthHeader from '../../components/Home/header/AuthHeader.vue'

export default defineComponent({
  name: 'LoginPage',
  components: {
    AuthHeader
  },
  beforeMount: function () {
    if (this.$route.params.username) {
      this.username = this.$route.params.username
    }

    if (this.$route.params.expired) {
      this.expired = true
    }
  },
  computed: {
    authStore: function () {
      return useAuthStore()
    },
    accountStore: function () {
      return useAccountStore()
    }
  },
  data: function () {
    return {
      username: '',
      expired: false,
      valid: false,
      loading: false,
      usernameRules: [(v: string) => !!v || this.$t('usernameError')],
      password: '',
      passwordRules: [(v: string) => !!v || this.$t('passwordError')],
      showPassword: false
    }
  },
  methods: {
    login: function () {
      const form = this.$refs.form as any
      form.validate()
      if (this.valid) {
        this.loading = true

        const authParams: LoginVM = {
          username: this.username,
          password: this.password
        }

        return this.authStore
          .authenticate(authParams)
          .then(async response => {
            const jwtToken = JWTToken.deserialize(response.data)
            this.authStore.setToken(jwtToken)
            config.isLoggedIn = true
            if (this.$route.params.returnUrl) {
              this.$router.push({ name: this.$route.params.returnUrl })
            } else {
              this.$router.push({ name: 'SendMoney' })
              this.$toast.success(this.$t('authenticated'))
            }
          })
          .catch((error: any) => {
            console.log(error)
            if (error.response.status === 400) {
              this.$toast.error(this.$t('invalidCredentials'))
            }
            if (error.response.status === 401) {
              this.$toast.error(this.$t('invalidCredentials'))
            }
            if (error.response.status === 500) {
              this.$toast.error(this.$t('error500Msg'))
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
})
