/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ClientDTO } from './client-dto'
/**
 *
 * @export
 * @interface DocumentDTO
 */
export interface DocumentDTO {
  /**
   *
   * @type {number}
   * @memberof DocumentDTO
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof DocumentDTO
   */
  docCodeRecto?: string
  /**
   *
   * @type {string}
   * @memberof DocumentDTO
   */
  docCodeVerso?: string
  /**
   *
   * @type {string}
   * @memberof DocumentDTO
   */
  docStatut?: DocumentDTODocStatutEnum
  /**
   *
   * @type {string}
   * @memberof DocumentDTO
   */
  typeDocument?: DocumentDTOTypeDocumentEnum
  /**
   *
   * @type {string}
   * @memberof DocumentDTO
   */
  downloadUrl?: string
  /**
   *
   * @type {string}
   * @memberof DocumentDTO
   */
  downloadUrl1?: string
  /**
   *
   * @type {Date}
   * @memberof DocumentDTO
   */
  expireDate?: Date
  /**
   *
   * @type {ClientDTO}
   * @memberof DocumentDTO
   */
  client?: ClientDTO
  /**
   *
   * @type {boolean}
   * @memberof DocumentDTO
   */
  onlyOnePage?: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum DocumentDTODocStatutEnum {
  MISSING = 'MISSING',
  ADDED = 'ADDED',
  VALIDE = 'VALIDE'
}
/**
 * @export
 * @enum {string}
 */
export enum DocumentDTOTypeDocumentEnum {
  CANADIANDRIVELICENCE = 'CANADIANDRIVELICENCE',
  CANADIANRESIDENTCARD = 'CANADIANRESIDENTCARD',
  PASSPORT = 'PASSPORT',
  ODEROFFICIALDOCUMENT = 'ODEROFFICIALDOCUMENT'
}

export class DocumentDTO implements DocumentDTO {
  constructor(data?: DocumentDTO) {
    Object.assign(this, data)
  }

  /**
   *
   * @param data
   * @returns
   */
  static deserialize(data: any): DocumentDTO {
    return new DocumentDTO({
      client: data.client ? ClientDTO.deserialize(data.client) : undefined,
      id: data.id,
      docCodeRecto: data.docCodeRecto,
      docCodeVerso: data.docCodeVerso,
      docStatut: data.docStatut,
      downloadUrl: data.downloadUrl,
      downloadUrl1: data.downloadUrl1,
      expireDate: data.expireDate,
      onlyOnePage: data.onlyOnePage,
      typeDocument: data.typeDocument
    })
  }
}
