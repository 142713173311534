import { DocumentDTO, DocumentDTODocStatutEnum, DocumentDTOTypeDocumentEnum } from '@/models'
import api from '@/services/api'
import { defineStore } from 'pinia'

export const useDocumentStore = defineStore({
  id: 'document',
  state: () => ({
    documents: [] as DocumentDTO[],
    licenseDocument: {} as DocumentDTO,
    residenceDocument: {} as DocumentDTO,
    passportDocument: {} as DocumentDTO,
    otherDocument: {} as DocumentDTO
  }),
  actions: {
    getUserDocuments: async function () {
      this.documents = []
      await api.get('/api/documents').then(response => {
        response.data.forEach((document: any) => {
          this.documents.push(DocumentDTO.deserialize(document))
        })
        this.getLicense()
        this.getResidence()
        this.getPassport()
        this.getOther()
      })
    },
    uploadDocument: async function (
      fileFront: File,
      fileBack: File,
      docType: DocumentDTOTypeDocumentEnum,
      expireDate: string,
      samePage: boolean
    ) {
      const formData = new FormData()
      formData.append('file', fileFront)
      if (!samePage) {
        formData.append('file2', fileBack)
      }
      return await api.post('/api/documents', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        params: {
          fileType: docType,
          expireDate: new Date(expireDate).toISOString(),
          isOnlyOnePage: samePage
        }
      })
    },
    compareDocumentType: function (doc: DocumentDTO, docType: DocumentDTOTypeDocumentEnum): boolean {
      return doc.typeDocument === docType
    },
    getLicense: function (): DocumentDTO | undefined {
      this.documents.forEach((item: any) => {
        if (this.compareDocumentType(item, DocumentDTOTypeDocumentEnum.CANADIANDRIVELICENCE)) {
          this.licenseDocument = item
          return item
        }
      })
      return undefined
    },
    getResidence: function (): DocumentDTO | undefined {
      this.documents.forEach((item: any) => {
        if (this.compareDocumentType(item, DocumentDTOTypeDocumentEnum.CANADIANRESIDENTCARD)) {
          this.residenceDocument = item
          return item
        }
      })
      return undefined
    },
    getPassport: function (): DocumentDTO | undefined {
      this.documents.forEach((item: any) => {
        if (this.compareDocumentType(item, DocumentDTOTypeDocumentEnum.PASSPORT)) {
          this.passportDocument = item
          return item
        }
      })
      return undefined
    },
    getOther: function (): DocumentDTO | undefined {
      this.documents.forEach((item: any) => {
        if (this.compareDocumentType(item, DocumentDTOTypeDocumentEnum.ODEROFFICIALDOCUMENT)) {
          this.otherDocument = item
          return item
        }
      })
      return undefined
    },
    hasTwoValidDocuments: function (): boolean {
      let count = 0
      this.documents.forEach((item: any) => {
        if (item.docStatut === DocumentDTODocStatutEnum.VALIDE) {
          count++
        }
      })
      return count >= 2
    },
    reset: function () {
      this.documents = []
      this.licenseDocument = {} as DocumentDTO
      this.residenceDocument = {} as DocumentDTO
      this.passportDocument = {} as DocumentDTO
      this.otherDocument = {} as DocumentDTO
    }
  }
})
