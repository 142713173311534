
import { defineComponent } from 'vue'
import NavBar from './NavBar.vue'

export default defineComponent({
  name: 'Header',
  components: {
    NavBar
  },
  data: () => ({})
})
