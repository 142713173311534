import { MiniPhoneDTOPhoneOperatorEnum, SendMoneyDto } from '@/models'
import api from '@/services/api'
import { defineStore } from 'pinia'
import { useRateStore } from '../xe/rate.store'

export const useMobileStore = defineStore({
  id: 'sendMoney',
  state: () => ({
    sendMoneyData: {} as SendMoneyDto,
    amountCAD: 0,
    amountXAF: 0,
    rateStore: useRateStore(),
    sendMoneyMethod: MiniPhoneDTOPhoneOperatorEnum.ORANGECAMEROUN,
    phoneOperatorList: [
      {
        text: 'Orange Money',
        value: MiniPhoneDTOPhoneOperatorEnum.ORANGECAMEROUN
      },
      {
        text: 'MTN Mobile Money',
        value: MiniPhoneDTOPhoneOperatorEnum.MTNCAMEROON
      }
    ]
  }),
  actions: {
    sendMoney: async function (paylaod: SendMoneyDto) {
      return await api.post('/api/transactions/sendmoney', paylaod)
    },
    // Convert CAD to XAF and get no decimal places
    convertCadToXaf: function () {
      if (this.amountCAD) {
        this.amountXAF = this.amountCAD * this.rateStore.currentRate
        this.amountXAF = Number(this.amountXAF.toFixed(2))

        if (this.amountXAF % 5 === 0) {
          this.amountXAF = Number(this.amountXAF.toFixed(2))
          return
        }
        const amountRounded = Number(5 - (this.amountXAF % 5) + Number(this.amountXAF))
        this.amountXAF = Number(amountRounded.toFixed(2))
      }
    },

    // Convert XAF to CAD and get 2 decimal places
    convertXafToCad: function () {
      if (this.amountXAF) {
        this.amountCAD = this.amountXAF / this.rateStore.currentRate
        this.amountCAD = Number(this.amountCAD.toFixed(2))
      }
    },

    // Set the amount in CAD
    setAmountCAD: function (amount: number) {
      this.amountCAD = amount
      this.convertCadToXaf()
    },

    // Set the amount in XAF
    setAmountXAF: function (amount: number) {
      this.amountXAF = Number(amount.toFixed(2))
      this.convertXafToCad()
    }
  }
})
