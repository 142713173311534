
import { useAuthStore } from '@/store/auth/auth.store'
import { defineComponent } from 'vue'
import AuthHeader from '../../components/Home/header/AuthHeader.vue'

export default defineComponent({
  name: 'HomePage',
  components: {
    AuthHeader
  },
  computed: {
    authStore: function () {
      return useAuthStore()
    }
  },
  data: function () {
    return {
      valid: false,
      email: '',
      loading: false,
      emailRules: [
        (v: string) => !!v || this.$t('emailError'),
        (v: string) => /.+@.+\..+/.test(v) || this.$t('emailNotValid')
      ]
    }
  },
  methods: {
    sendResetCode: function () {
      const forms = this.$refs.forms as any
      forms.validate()
      if (this.valid) {
        this.loading = true
        return this.authStore
          .forgotPasswordInit(this.email)
          .then(() => {
            this.$router.push({
              name: 'ResetPassword',
              params: { email: this.email }
            })
          })
          .catch((error: any) => {
            if (error.response.status === 500) {
              this.$toast.error(this.$t('emailNotRegistered'))
            }
            if (error.response.status === 401) {
              this.$toast.error(this.$t('error401Msg'))
            }
            if (error.response.status === 400) {
              this.$toast.error(this.$t('error400Msg'))
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
})
