import { AddressDTO, AdminUserDTO, EditAccountRequestDto } from '@/models'
import api from '@/services/api'
import { defineStore } from 'pinia'

export const useAccountStore = defineStore({
  id: 'account',
  state: () => ({
    accountData: localStorage.accountData
      ? (JSON.parse(atob(localStorage.accountData as string)) as AdminUserDTO)
      : ({} as AdminUserDTO)
  }),
  actions: {
    refreshData: async function () {
      await api.get('/api/account').then((response: { data: any }) => {
        localStorage.setItem('accountData', btoa(JSON.stringify(response.data)))
        this.accountData = response.data
      })
    },

    updateUser: async function (payload: EditAccountRequestDto) {
      return await api.put('/api/update-account', JSON.stringify(payload))
    },

    updateUserAddress: async function (payload: AddressDTO, id: number) {
      return await api.put(`/api/addresses/${id}`, JSON.stringify(payload))
    }
  }
})
