/* tslint:disable */
/* eslint-disable */
/**
 * Transfert API
 * Transfert API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @interface ClientDTO
 */
export interface ClientDTO {
  /**
   *
   * @type {number}
   * @memberof ClientDTO
   */
  balance?: number
  /**
   *
   * @type {Date}
   * @memberof ClientDTO
   */
  birthDate: Date
  /**
   *
   * @type {string}
   * @memberof ClientDTO
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof ClientDTO
   */
  firstName: string
  /**
   *
   * @type {number}
   * @memberof ClientDTO
   */
  id?: number
  /**
   *
   * @type {boolean}
   * @memberof ClientDTO
   */
  identityCheck?: boolean
  /**
   *
   * @type {string}
   * @memberof ClientDTO
   */
  language?: ClientDTOLanguageEnum
  /**
   *
   * @type {string}
   * @memberof ClientDTO
   */
  lastName: string
  /**
   *
   * @type {number}
   * @memberof ClientDTO
   */
  limite?: number
  /**
   *
   * @type {string}
   * @memberof ClientDTO
   */
  phoneNumber?: string
}

/**
 * @export
 * @enum {string}
 */
export enum ClientDTOLanguageEnum {
  EN = 'EN',
  FR = 'FR'
}

export class ClientDTO implements ClientDTO {
  constructor(data?: ClientDTO) {
    Object.assign(this, data)
  }

  /**
   *
   * @param data
   * @returns
   */
  static deserialize(data: any): ClientDTO {
    return new ClientDTO({
      balance: data.balance,
      birthDate: data.birthDate,
      email: data.email,
      firstName: data.firstName,
      id: data.id,
      identityCheck: data.identityCheck,
      language: data.language,
      lastName: data.lastName,
      limite: data.limite,
      phoneNumber: data.phoneNumber
    })
  }
}
