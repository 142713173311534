
import { defineComponent } from 'vue'
import BalanceAccount from '@/components/dashboard/BalanceAccount.vue'
import Countries from '@/assets/files/countries.json'
import { useBillStore } from '@/store/transactions/bill.store'
import { PayBillDto, SearchBillDto, Service } from '@/models'
import { useRateStore } from '@/store/xe/rate.store'
import { DeviseType } from '@/helpers/enum'
import { useAccountStore } from '@/store/auth/account.store'
import { formatAmount } from '@/helpers/functions'

export default defineComponent({
  name: 'PayBill',
  components: {
    BalanceAccount
  },
  beforeMount: function () {
    this.billStore.getProviders()
  },
  computed: {
    accountStore: function () {
      return useAccountStore()
    },
    billStore: function () {
      return useBillStore()
    },
    rateStore: function () {
      return useRateStore()
    },
    dueDate: function (): string | undefined {
      if (!this.billStore.bill.billDueDate) {
        return undefined
      }

      const date = new Date(
        this.billStore.bill.billDueDate?.year!,
        this.billStore.bill.billDueDate?.monthValue!,
        this.billStore.bill.billDueDate?.dayOfMonth!
      )

      return date.toLocaleDateString()
    },
    billAmount: function (): number | undefined {
      if (!this.billStore.bill.amountLocalCur) {
        return undefined
      }
      const amount: string = (this.billStore.bill.amountLocalCur / this.rateStore.currentRate).toFixed(2)
      return amount as unknown as number
    },
    amountXAFBillAmount: function (): number | undefined {
      if (!this.billStore.bill.amountLocalCur) {
        return undefined
      }

      return this.billStore.bill.amountLocalCur
    },
    debitAmount: function (): number | undefined {
      if (!this.billStore.bill.priceLocalCur) {
        return undefined
      }
      const amount: string = (this.billStore.bill.priceLocalCur / this.rateStore.currentRate).toFixed(2)
      return amount as unknown as number
    },
    amountXAFDebitAmount: function (): number | undefined {
      if (!this.billStore.bill.priceLocalCur) {
        return undefined
      }

      return this.billStore.bill.priceLocalCur
    }
  },
  destroyed: function () {
    this.billStore.resetBill()
  },
  data: function () {
    return {
      countries: Countries,
      selectedCountry: { name: 'Cameroon', dial_code: '+237', code: 'CM', flag: '🇨🇲' },
      serviceProvider: null,
      selectedProvider: null as unknown as Service,
      billNumber: '',
      payBillLoading: false,
      searchingBill: false,
      errorMessage: '',
      showErrorMessage: false,
      successMessage: '',
      showSuccessMessage: false,
      deviseType: DeviseType,
      showConfirmPayBill: false,
      serviceLogos: {
        CAMWATER: require('@/assets/images/camwater.jpg'),
        ENEO: require('@/assets/images/eneo.jpg'),
        CMDGI: require('@/assets/images/cmdgi.jpg'),
        CMDOUANES: require('@/assets/images/cmdouanes.jpg')
      } as { [name: string]: string },
      formatAmount
    }
  },
  methods: {
    searchBill: function () {
      const payload: SearchBillDto = {
        merchant: this.selectedProvider.merchant,
        serviceId: this.selectedProvider.serviceid,
        billNumber: this.billNumber
      }
      this.searchingBill = true
      this.billStore
        .searchBill(payload)
        .then(response => {
          if (response.data[0]) {
            this.billStore.bill = response.data[0]
            this.showErrorMessage = false
          } else {
            this.errorMessage = this.$t('noBillFound') as string
            this.showErrorMessage = true
            this.showSuccessMessage = false
          }
        })
        .catch(err => {
          if (err.response.status === 400) {
            this.errorMessage = err.response.data.title as string
            this.showErrorMessage = true
            this.showSuccessMessage = false
          }
          if (err.response.status === 403) {
            this.errorMessage = err.response.data.title as string
            this.showErrorMessage = true
            this.showSuccessMessage = false
          }
          if (err.response.status === 500) {
            this.errorMessage = err.response.data.title as string
            this.showErrorMessage = true
            this.showSuccessMessage = false
          }
        })
        .finally(() => {
          this.searchingBill = false
        })
    },

    payBill: function () {
      const payload: PayBillDto = {
        merchant: this.selectedProvider.merchant,
        amount: this.amountXAFDebitAmount!,
        amountCAD: this.debitAmount,
        transactionFee: 0.0,
        transactionRate: this.rateStore.currentRate,
        payItemId: this.billStore.bill.payItemId!,
        customerNumber: this.billStore.bill.customerNumber!,
        serviceNumber: this.billStore.bill.billNumber!,
        quoteId: this.billStore.bill.quoteId,
        priceLocalCur: this.billStore.bill.priceLocalCur
      }

      this.searchingBill = true

      this.billStore
        .payBill(payload)
        .then(async () => {
          await this.accountStore.refreshData()
          this.showErrorMessage = false
          this.showSuccessMessage = true
          this.successMessage = this.$t('billPaidSuccess') as string
          this.billStore.resetBill()
          this.selectedProvider = null as unknown as Service
          this.billNumber = ''
          this.showConfirmPayBill = false
        })
        .catch(err => {
          if (err.response.status === 400) {
            this.errorMessage = err.response.data.title as string
            this.showErrorMessage = true
            this.showSuccessMessage = false
          }
          if (err.response.status === 403) {
            this.errorMessage = err.response.data.title as string
            this.showErrorMessage = true
            this.showSuccessMessage = false
          }
          if (err.response.status === 500) {
            this.errorMessage = err.response.data.title as string
            this.showErrorMessage = true
            this.showSuccessMessage = false
          }
        })
        .finally(() => {
          this.searchingBill = false
        })
    },
    getServiceLogo(name: string): string {
      return this.serviceLogos[name] || ''
    }
  }
})
