import { MavianceBill, PayBillDto, SearchBillDto, Service } from '@/models'
import api from '@/services/api'
import { defineStore } from 'pinia'

export const useBillStore = defineStore({
  id: 'payAbill',
  state: () => ({
    providers: [] as Service[],
    bill: {} as unknown as MavianceBill
  }),
  actions: {
    getProviders: async function () {
      await api.get('/api/bill/searchable').then(response => {
        this.providers = response.data
      })
    },

    searchBill: async function (payload: SearchBillDto) {
      return await api.post('/api/transactions/search-bill', JSON.stringify(payload))
    },

    payBill: async function (payload: PayBillDto) {
      return await api.post('/api/transactions/paybill', JSON.stringify(payload))
    },

    resetBill: function () {
      this.bill = {} as unknown as MavianceBill
    }
  }
})
