/* tslint:disable */
/* eslint-disable */
/**
 * Transfert API
 * Transfert API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface Service
 */
export interface Service {
    /**
     * Category of service
     * @type {string}
     * @memberof Service
     */
    category: string;
    /**
     * Country of operation (ISO 3166-1 alpha-3)
     * @type {string}
     * @memberof Service
     */
    country: string;
    /**
     * Service payment denomination. The payment amount must be a multiple of the denomination value. Example:  |Denomination|Amount|Valid| |-----|-----|-------| |1|100|true| |5|100|true| |200|100|false| |50|60|false| 
     * @type {number}
     * @memberof Service
     */
    denomination?: number;
    /**
     * Service description
     * @type {string}
     * @memberof Service
     */
    description: string;
    /**
     * If set to true (1), then the service number provided for this service can be verified before making a payment request
     * @type {boolean}
     * @memberof Service
     */
    isVerifiable: boolean;
    /**
     * Local currency of service. (Format: ISO 4217)
     * @type {string}
     * @memberof Service
     */
    localCur: string;
    /**
     * Unique  merchant code
     * @type {string}
     * @memberof Service
     */
    merchant: string;
    /**
     * Unique  service Identifier. Use this value whenever “serviceid” is required in request parameters
     * @type {number}
     * @memberof Service
     */
    serviceid: number;
    /**
     * Service availability status
     * @type {string}
     * @memberof Service
     */
    status: ServiceStatusEnum;
    /**
     * Public name of service
     * @type {string}
     * @memberof Service
     */
    title: string;
    /**
     * Type of service. This API will only provide services of the type
     * @type {string}
     * @memberof Service
     */
    type: ServiceTypeEnum;
    /**
     * Optional mask for the service number entered during a payment for client side validations. All service numbers must comply to the mask in order to pass. The mask is a PCRE regular expression
     * @type {string}
     * @memberof Service
     */
    validationMask?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ServiceStatusEnum {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}
/**
    * @export
    * @enum {string}
    */
export enum ServiceTypeEnum {
    CASHIN = 'CASHIN',
    CASHOUT = 'CASHOUT',
    NONSEARCHABLEBILL = 'NON_SEARCHABLE_BILL',
    PRODUCT = 'PRODUCT',
    SEARCHABLEBILL = 'SEARCHABLE_BILL',
    SUBSCRIPTION = 'SUBSCRIPTION',
    TOPUP = 'TOPUP',
    VOUCHER = 'VOUCHER'
}

