import api from '@/services/api'
import { defineStore } from 'pinia'

export const useSettingsStore = defineStore({
  id: 'settings',
  state: () => ({
    language: 'fr',
    notification: true
  }),
  actions: {
    async updateLanguage() {
      const langkey = this.language.toUpperCase()
      return await api.patch(`/api/account/update-language/${langkey}`)
    },
    setNotification(value: boolean) {
      this.notification = value
    }
  }
})
