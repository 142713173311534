import { defineStore } from 'pinia'
import api from '@/services/api'
import { SendMailData } from '@/models'

export const useContactStore = defineStore({
  id: 'contacts',
  actions: {
    sendContactMessage: async function (payload: SendMailData) {
      return await api.post('/api/contact-us', JSON.stringify(payload))
    }
  }
})
