
import { defineComponent } from 'vue'
import Footer from '@/components/Home/footer/Footer.vue'
import Header from '@/components/dashboard/Header.vue'
import { useAuthStore } from '@/store/auth/auth.store'
import { useIdle, useTimestamp } from '@vueuse/core'
import { useAccountStore } from '@/store/auth/account.store'
import { formatAmount } from '@/helpers/functions'
import { DeviseType, ServicesMerchant } from '@/helpers/enum'
import { ToastOptions } from 'vue-toastification/dist/types/src/types'
import { POSITION, TYPE } from 'vue-toastification'

const { idle, lastActive } = useIdle(5 * 60 * 1000)
const now = useTimestamp()

export default defineComponent({
  name: 'Dashboard',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      inactivityTimer: 0 as number,
      expireTokentimer: 0 as number,
      idelItem: idle,
      timerCount: 1810,
      formatAmount,
      DeviseType
    }
  },
  computed: {
    authStore: function () {
      return useAuthStore()
    },
    idledFor: function () {
      return Math.floor((now.value - lastActive.value) / 1000)
    },
    accountStore: function () {
      return useAccountStore()
    }
  },
  async beforeMount() {
    await this.accountStore.refreshData()
    const userData = this.accountStore.accountData
    this.$i18n.locale = userData.langKey!.toLowerCase()
    this.checkStatusOfServices()
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--
          }, 1000)
        }
        if (value === 10) {
          this.authStore.setReturnUrl(this.$route.name as string)
          this.authStore.logout('true')
          this.$swal('session expire in')
        }
      },
      immediate: true
    },
    idelItem: {
      handler(idlev) {
        if (idlev === true) {
          this.authStore.setReturnUrl(this.$route.name as string)
          this.authStore.logout('true')
          // this.$swal('session expire in')
        }
      },
      immediate: true
    }
  },
  methods: {
    // Logout after 5 minutes of inactivity
    resetTimer() {
      clearTimeout(this.inactivityTimer)
      this.inactivityTimer = setTimeout(() => {
        this.authStore.setReturnUrl(this.$route.name as string)
        this.authStore.logout('true')
      }, 5 * 60 * 1000)
    },

    // Logout after 15 minutes
    timeout() {
      this.expireTokentimer = setTimeout(() => {
        this.authStore.setReturnUrl(this.$route.name as string)
        this.authStore.logout('true')
      }, 30 * 60 * 1000)
    },

    checkStatusOfServices() {
      if (
        this.accountStore.accountData.unAvailableService != null &&
        this.accountStore.accountData.unAvailableService.length > 0
      ) {
        // Persistence toast
        const options: ToastOptions & { type?: TYPE.ERROR | undefined } = {
          closeOnClick: false,
          timeout: 5000 * 60,
          position: POSITION.BOTTOM_RIGHT,
          draggable: false,
          icon: true,
          type: TYPE.ERROR
        }

        this.accountStore.accountData.unAvailableService.forEach(service => {
          if (service.mavianceSercie?.merchant === ServicesMerchant.CMORANGEMOMO) {
            this.$toast.error(this.$t('orangeMoneyIsUnavailable'), options)
          } else if (service.mavianceSercie?.merchant === ServicesMerchant.MTNMOMO) {
            this.$toast.error(this.$t('mtnMoneyIsUnavailable'), options)
          } else if (service.mavianceSercie?.merchant === ServicesMerchant.ENEO) {
            this.$toast.error(this.$t('eneoIsUnavailable'), options)
          } else if (service.mavianceSercie?.merchant === ServicesMerchant.CAMWATER) {
            this.$toast.error(this.$t('camwaterIsUnavailable'), options)
          } else if (service.mavianceSercie?.merchant === ServicesMerchant.CMDGI) {
            this.$toast.error(this.$t('cmdgiIsUnavailable'), options)
          } else if (service.mavianceSercie?.merchant === ServicesMerchant.CMDOUANES) {
            this.$toast.error(this.$t('cmdouanesIsUnavailable'), options)
          }
        })
      }
    }
  }
})
