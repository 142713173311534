/* tslint:disable */
/* eslint-disable */
/**
 * Transfert API
 * Transfert API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @interface MiniPhoneDTO
 */
export interface MiniPhoneDTO {
  /**
   *
   * @type {string}
   * @memberof MiniPhoneDTO
   */
  dialCode?: string
  /**
   *
   * @type {number}
   * @memberof MiniPhoneDTO
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof MiniPhoneDTO
   */
  phoneNumber?: string
  /**
   *
   * @type {string}
   * @memberof MiniPhoneDTO
   */
  phoneOperator?: MiniPhoneDTOPhoneOperatorEnum
}

/**
 * @export
 * @enum {string}
 */
export enum MiniPhoneDTOPhoneOperatorEnum {
  MTNCAMEROON = 'MTNCAMEROON',
  ORANGECAMEROUN = 'ORANGECAMEROUN'
}

/**
 * @exports
 * @class MiniPhoneDTO
 */
export class MiniPhoneDTO implements MiniPhoneDTO {
  constructor(data?: MiniPhoneDTO) {
    Object.assign(this, data)
  }

  /**
   *
   * @param data
   * @returns
   */
  static deserialize(data: any): MiniPhoneDTO {
    return new MiniPhoneDTO(data)
  }
}
