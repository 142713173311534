
import AppDownload from '@/components/Home/body/AppDownload.vue'
import WhyUs from '@/components/Home/body/WhyUs.vue'
import SendMoneyForm from '@/components/Home/header/SendMoneyForm.vue'
import { defineComponent } from 'vue'
import HowItWorks from '../components/Home/body/HowItWorks.vue'
import WhatCanYouDo from '../components/Home/body/WhatCanYouDo.vue'
import WhyChooseUS from '../components/Home/body/WhyChooseUS.vue'
import Footer from '../components/Home/footer/Footer.vue'
import Header from '../components/Home/header/Header.vue'
import QrCodeGenerator from '../components/Home/QRCode.vue'

export default defineComponent({
  name: 'HomePage',
  components: {
    AppDownload,
    Header,
    WhyChooseUS,
    WhatCanYouDo,
    HowItWorks,
    Footer,
    SendMoneyForm,
    QrCodeGenerator,
    WhyUs
  }
})
