/* tslint:disable */
/* eslint-disable */
/**
 * Transfert API
 * Transfert API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MiniAccountBankDTO } from './mini-account-bank-dto'
import { MiniPhoneDTO } from './mini-phone-dto'
/**
 *
 * @export
 * @interface ReceiverDTO
 */
export interface ReceiverDTO {
  /**
   *
   * @type {Array<MiniAccountBankDTO>}
   * @memberof ReceiverDTO
   */
  bankAccounts?: Array<MiniAccountBankDTO>
  /**
   *
   * @type {string}
   * @memberof ReceiverDTO
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof ReceiverDTO
   */
  firstName: string
  /**
   *
   * @type {number}
   * @memberof ReceiverDTO
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof ReceiverDTO
   */
  lastName: string
  /**
   *
   * @type {Array<MiniPhoneDTO>}
   * @memberof ReceiverDTO
   */
  phones?: Array<MiniPhoneDTO>

  /**
   * @type {boolean}
   * @memberof ReceiverDTO
   */
  saved?: boolean
}

export class ReceiverDTO implements ReceiverDTO {
  constructor(data?: ReceiverDTO) {
    Object.assign(this, data)
  }

  // Deserializes JSON data into an instance of the ReceiverDTO class
  static deserialize(data: any): ReceiverDTO {
    return new ReceiverDTO({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      id: data.id,
      bankAccounts: (data.bankAccounts as any[]).map((bankAccount: any) => MiniAccountBankDTO.deserialize(bankAccount)),
      phones: (data.phones as any[]).map((phone: any) => MiniPhoneDTO.deserialize(phone)),
      saved: data.saved
    })
  }
}
