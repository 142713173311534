
import { MiniAccountBankDTO, MiniPhoneDTO, MiniPhoneDTOPhoneOperatorEnum, ReceiverDTO } from '@/models'
import { useReceiverFormStore } from '@/store/receiver/receiver.store'
import { emailRegexNoRequired, phoneNumberCM, regexMTN, regexOrange } from '@/helpers/constants'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ReceiverForm',
  props: {
    mode: {
      type: String,
      default: 'add',
      validator: (value: string) => ['add', 'edit'].includes(value)
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    receiverFormStore() {
      return useReceiverFormStore()
    }
  },
  watch: {
    dialog() {
      if (this.mode === 'edit' && this.dialog) {
        this.phones = this.receiverFormStore.receiver.phones!
        this.bankAccountForm = this.checkMiniAccountBankDTO()
      }
    },
    'phoneForm.phoneNumber'(val: string) {
      if (regexOrange.test(val)) this.phoneForm.phoneOperator = this.phoneOperator.ORANGECAMEROUN
      if (regexMTN.test(val)) this.phoneForm.phoneOperator = this.phoneOperator.MTNCAMEROON
      if (!regexMTN.test(val) && !regexOrange.test(val)) this.phoneForm.phoneOperator = undefined
    }
  },
  data() {
    return {
      firstNameRules: [(v: string) => !!v || this.$t('firstNameError')],
      lastNameRules: [(v: string) => !!v || this.$t('lastNameError')],
      emailRules: [(v = '') => emailRegexNoRequired.test(v) || this.$t('emailNotValid')],
      phoneNumberRules: [(v: string) => phoneNumberCM.test(v) || this.$t('phoneNumberNotValid')],
      phoneOperatorRules: [(v: string) => !!v || this.$t('requiredField')],
      phoneForm: new MiniPhoneDTO({
        dialCode: '237',
        phoneNumber: '',
        phoneOperator: undefined
      }),
      phoneOperator: MiniPhoneDTOPhoneOperatorEnum,
      phoneOperators: [
        { text: 'MTN', value: MiniPhoneDTOPhoneOperatorEnum.MTNCAMEROON },
        { text: 'Orange', value: MiniPhoneDTOPhoneOperatorEnum.ORANGECAMEROUN }
      ],
      showPhonesForm: false,
      showBankAccountForm: false,
      phones: [] as MiniPhoneDTO[],
      bankAccountForm: {} as MiniAccountBankDTO,
      isValid: false
    }
  },
  methods: {
    submitForm() {
      if (this.phones.length === 0) {
        this.$toast.error(this.$t('addLessOnePhoneNumbers'))
        return
      }

      const form = this.$refs.form as any
      form.validate()

      // remove '+' from dial code
      this.phones.forEach(phone => {
        phone.dialCode = phone.dialCode!.replace('+', '')
      })

      if (this.isValid) {
        this.receiverFormStore.receiver.phones = this.phones

        // check if bank account form is filled
        if (
          this.bankAccountForm.bankName &&
          this.bankAccountForm.accountNumber &&
          this.bankAccountForm.accountKey &&
          this.bankAccountForm.bankBranch
        ) {
          this.receiverFormStore.receiver.bankAccounts = [this.bankAccountForm]
        }

        this.receiverFormStore.receiver.saved = true

        if (this.mode === 'add') {
          this.$emit('create', this.receiverFormStore.receiver)
        } else if (this.mode === 'edit') {
          this.$emit('update', this.receiverFormStore.receiver)
        }
      }
    },
    cancel() {
      this.$emit('cancel')
      this.receiverFormStore.receiver = new ReceiverDTO()
      this.phones = []
      this.showPhonesForm = false
      this.showBankAccountForm = false
      this.phoneForm = new MiniPhoneDTO({
        dialCode: '237',
        phoneNumber: '',
        phoneOperator: MiniPhoneDTOPhoneOperatorEnum.MTNCAMEROON
      })
      this.bankAccountForm = new MiniAccountBankDTO()
    },
    AddPhoneNumber() {
      if (this.phoneForm.phoneNumber && this.phoneForm.phoneOperator) {
        this.phoneForm.phoneNumber = this.phoneForm.phoneNumber.substring(0, 9)
        this.phones.push({ ...this.phoneForm })
        this.phoneForm = new MiniPhoneDTO({
          dialCode: '237',
          phoneNumber: '',
          phoneOperator: MiniPhoneDTOPhoneOperatorEnum.MTNCAMEROON
        })
        this.showPhonesForm = false
      }
    },
    removePhone(phone: MiniPhoneDTO) {
      this.phones = this.phones.filter(p => p.id !== phone.id)
    },
    checkMiniAccountBankDTO: function (): MiniAccountBankDTO {
      if (this.receiverFormStore.receiver.bankAccounts) {
        if (this.receiverFormStore.receiver.bankAccounts.length > 0) {
          return this.receiverFormStore.receiver.bankAccounts[0]
        }
      }
      return new MiniAccountBankDTO()
    },
    editPhone(phone: MiniPhoneDTO) {
      this.phoneForm = phone
      this.removePhone(phone)
      this.showPhonesForm = true
    }
  }
})
