
import { defineComponent } from 'vue'
import BalanceAccount from '@/components/dashboard/BalanceAccount.vue'
import { useSettingsStore } from '@/store/settings/settings.store'

export default defineComponent({
  name: 'Settings',
  components: {
    BalanceAccount
  },
  computed: {
    settingsStore: function () {
      return useSettingsStore()
    }
  },
  beforeMount() {
    this.settingsStore.language = this.$i18n.locale.toUpperCase()
  },
  data() {
    return {
      language: this.$i18n.locale.toUpperCase(),
      languages: [
        { text: 'English', value: 'EN' },
        { text: 'Français', value: 'FR' }
      ]
    }
  },
  methods: {
    async changeLanguage() {
      this.$i18n.locale = this.settingsStore.language.toLowerCase()
      await this.settingsStore.updateLanguage()
    }
  }
})
