
import { useMenuStore } from '@/store/menu.store'
import { defineComponent } from 'vue'
import { RawLocation } from 'vue-router'

export default defineComponent({
  name: 'MenuMobile',
  setup() {
    const menuStore = useMenuStore()
    return {
      menuStore
    }
  },
  computed: {
    selectedLanguage() {
      if (this.$i18n.locale === 'en') {
        return 'English'
      }
      return 'Français'
    }
  },
  methods: {
    navigateTo(route: RawLocation) {
      this.$router.push(route)
      this.menuStore.toggleDrawer()
    }
  }
})
