/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Era } from './era';
import { IsoChronology } from './iso-chronology';
/**
 * 
 * @export
 * @interface LocalDate
 */
export interface LocalDate {
    /**
     * 
     * @type {number}
     * @memberof LocalDate
     */
    year?: number;
    /**
     * 
     * @type {string}
     * @memberof LocalDate
     */
    month?: LocalDateMonthEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LocalDate
     */
    leapYear?: boolean;
    /**
     * 
     * @type {IsoChronology}
     * @memberof LocalDate
     */
    chronology?: IsoChronology;
    /**
     * 
     * @type {Era}
     * @memberof LocalDate
     */
    era?: Era;
    /**
     * 
     * @type {number}
     * @memberof LocalDate
     */
    monthValue?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalDate
     */
    dayOfMonth?: number;
    /**
     * 
     * @type {string}
     * @memberof LocalDate
     */
    dayOfWeek?: LocalDateDayOfWeekEnum;
    /**
     * 
     * @type {number}
     * @memberof LocalDate
     */
    dayOfYear?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum LocalDateMonthEnum {
    JANUARY = 'JANUARY',
    FEBRUARY = 'FEBRUARY',
    MARCH = 'MARCH',
    APRIL = 'APRIL',
    MAY = 'MAY',
    JUNE = 'JUNE',
    JULY = 'JULY',
    AUGUST = 'AUGUST',
    SEPTEMBER = 'SEPTEMBER',
    OCTOBER = 'OCTOBER',
    NOVEMBER = 'NOVEMBER',
    DECEMBER = 'DECEMBER'
}
/**
    * @export
    * @enum {string}
    */
export enum LocalDateDayOfWeekEnum {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY'
}

