
import { defineComponent } from 'vue'
import Header from '@/components/Home/header/Header.vue'
import Footer from '@/components/Home/footer/Footer.vue'

export default defineComponent({
  name: 'PrivacyPolicy',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      contactUs: 'developer@gtfinance.ca'
    }
  }
})
