/* tslint:disable */
/* eslint-disable */
/**
 * Transfert API
 * Transfert API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Client } from './client';
import { Country } from './country';
import { Receiver } from './receiver';
/**
 * 
 * @export
 * @interface Phone
 */
export interface Phone {
    /**
     * 
     * @type {Client}
     * @memberof Phone
     */
    client?: Client;
    /**
     * 
     * @type {Country}
     * @memberof Phone
     */
    country?: Country;
    /**
     * 
     * @type {number}
     * @memberof Phone
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Phone
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Phone
     */
    phoneOperator?: PhonePhoneOperatorEnum;
    /**
     * 
     * @type {Receiver}
     * @memberof Phone
     */
    receiver?: Receiver;
}

/**
    * @export
    * @enum {string}
    */
export enum PhonePhoneOperatorEnum {
    MTNCAMEROON = 'MTNCAMEROON',
    ORANGECAMEROUN = 'ORANGECAMEROUN'
}

