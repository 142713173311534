
import { postalCodeRegex } from '@/helpers/constants'
import { AddressDTO } from '@/models'
import { defineComponent } from 'vue'
import Provinces from '@/assets/files/canadian-provinces.json'
import { useAccountStore } from '@/store/auth/account.store'

export default defineComponent({
  name: 'EditAddressSection',
  computed: {
    accountStore: function () {
      return useAccountStore()
    }
  },
  beforeMount() {
    this.address = this.accountStore.accountData.address!
  },
  data: function () {
    return {
      address: {} as AddressDTO,
      isEdit: false,
      clientAddressValid: false,
      provinces: Provinces,
      streetNumberRules: [(v: string) => !!v || this.$t('streetNumberError')],
      apartNumberRules: [
        // (v: string) => !!v || this.$t('apartmentNumberError')
      ],
      postalCodeRules: [
        (v: string) => !!v || this.$t('postalCodeError'),
        (v: string) => postalCodeRegex.test(v) || this.$t('postalCodeNotValid')
      ],
      cityRules: [(v: string) => !!v || this.$t('cityError')],
      provinceRules: [(v: string) => !!v || this.$t('provinceError')],
      showLoader: false
    }
  },
  methods: {
    async updateUserAddress() {
      const form = this.$refs.clientAddress as any
      form.validate()
      if (this.clientAddressValid) {
        this.showLoader = true

        this.accountStore
          .updateUserAddress(this.address, this.address.id!)
          .then(async () => {
            this.$toast.success(this.$t('userAddressUpdated'))
            await this.accountStore.refreshData()
          })
          .catch(error => {
            if (error.response.status === 400) {
              this.$toast.error(this.$t('error400Msg'))
            }
            if (error.response.status === 401) {
              this.$toast.error(this.$t('error401Msg'))
            }
            if (error.response.status === 500) {
              this.$toast.error(this.$t('error500Msg'))
            }
          })
          .finally(() => {
            this.showLoader = false
          })
      }
    }
  }
})
