
import { defineComponent } from 'vue'
import { useMobileStore } from '@/store/transactions/mobile.store'
import { MiniPhoneDTOPhoneOperatorEnum } from '@/models'
import { DeviseType } from '@/helpers/enum'
import { formatAmount } from '@/helpers/functions'

export default defineComponent({
  name: 'ConfirmSendMoneyModal',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    phoneNumber: {
      type: Number,
      required: true
    },
    receiveName: {
      type: String,
      required: true
    },
    selectedCountry: {
      type: Object,
      required: true
    },
    roundAmountCAD: {
      type: Number,
      required: true
    },
    roundAmountXAF: {
      type: String,
      required: true
    }
  },
  computed: {
    showConfirmSendMoney: {
      get(): boolean {
        return this.value
      },
      set(value: boolean) {
        this.$emit('close', value)
      }
    },
    mobileStore: function () {
      return useMobileStore()
    }
  },
  data() {
    return {
      phoneOperator: MiniPhoneDTOPhoneOperatorEnum,
      deviseType: DeviseType,
      formatAmount
    }
  },
  methods: {
    sendMoney(): void {
      this.$emit('sendMoney')
      this.showConfirmSendMoney = false
    }
  }
})
