import api from '@/services/api'
import { defineStore } from 'pinia'

/**
 * Check if email is already used
 */
export const checkEmailStore = defineStore({
  id: 'checkEmail',
  state: () => ({
    emailUsed: false
  }),
  actions: {
    checkEmail: async function (email: string) {
      return await api.get('/api/check-email', { params: { email: email } })
    },

    // Set emailUsed to true if email is already used
    // Set emailUsed to false if email is not used
    setEmailState: function (value: boolean) {
      this.emailUsed = value
    },
    reset: function () {
      this.emailUsed = false
    }
  }
})

/**
 * Check if login is already used
 */
export const checkLoginStore = defineStore({
  id: 'checkLogin',
  state: () => ({
    loginUsed: false,
    isLoginValid: true
  }),
  actions: {
    checkLogin: async function (login: string) {
      return await api.get('/api/check-login', { params: { login: login } })
    },

    // Set loginUsed to true if login is already used
    // Set loginUsed to false if login is not used
    setLoginState: function (value: boolean) {
      this.loginUsed = value
    },

    // Set isLoginValid to true if login is valid
    // Set isLoginValid to false if login is not valid
    setLoginValidState: function (value: boolean) {
      this.isLoginValid = value
    },
    reset: function () {
      this.loginUsed = false
      this.isLoginValid = true
    }
  }
})

/**
 * Check if phone is already used
 */
export const checkPhoneStore = defineStore({
  id: 'checkPhone',
  state: () => ({
    phoneUsed: false
  }),
  actions: {
    checkPhone: async function (phone: string) {
      return await api.get('/api/phones/check', { params: { phoneNumber: phone } })
    },

    // Set phoneUsed to true if phone is already used
    // Set phoneUsed to false if phone is not used
    setPhoneState: function (value: boolean) {
      this.phoneUsed = value
    },

    reset: function () {
      this.phoneUsed = false
    }
  }
})

/**
 * Check if referral code exist
 */
export const checkExistCodeStore = defineStore({
  id: 'checkExistCode',
  state: () => ({
    codeExist: true
  }),
  actions: {
    checkCode: async function (code: string) {
      return await api.get(`/api/admin/check-ref-code/GTF-${code}`)
    },

    setCodeState: function (value: boolean) {
      this.codeExist = value
    }
  }
})
