
import { passwordPattern } from '@/helpers/constants'
import { KeyAndPasswordVM } from '@/models'
import { useAuthStore } from '@/store/auth/auth.store'
import { defineComponent } from 'vue'
import AuthHeader from '../../components/Home/header/AuthHeader.vue'

export default defineComponent({
  name: 'ResetPassword',
  components: {
    AuthHeader
  },
  computed: {
    authStore: function () {
      return useAuthStore()
    }
  },
  data: function () {
    return {
      valid: false,
      loading: false,
      code: '',
      newPassword: '',
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      codeRules: [(v: string) => !!v || '', (v: string) => v.length === 5 || ''],
      passwordRules: [
        (v: string) => !!v || this.$t('passwordError'),
        (v: string) => passwordPattern.test(v) || this.$t('passwordNotValid')
      ],
      confirmPasswordRules: [(v: string) => !!v || this.$t('confirmPasswordError')]
    }
  },
  methods: {
    resetPassword: function () {
      const form = this.$refs.forms as any
      form.validate()

      if (!this.valid) {
        return
      }

      this.loading = true

      const payload: KeyAndPasswordVM = {
        email: this.$route.params.email,
        key: this.code,
        newPassword: this.newPassword
      }

      return this.authStore
        .forgotPasswordFinit(payload)
        .then(() => {
          this.$router.push({ name: 'Login' })
          this.$toast.success(this.$t('passwordResetSuccess'))
        })
        .catch((error: any) => {
          if (error.response.status === 500) {
            this.$toast.error(this.$t('resetCodeInvalid'))
          }
          if (error.response.status === 400) {
            this.$toast.error(this.$t('error400Msg'))
          }
          if (error.response.status === 401) {
            this.$toast.error(this.$t('error401Msg'))
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    confirmPasswordError: function () {
      return this.confirmPassword !== this.newPassword
    }
  }
})
